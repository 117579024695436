import ReactDOM from "react-dom";
import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField} from "@material-ui/core";
import {
    Divider,
    Button,
    DialogContent,
    FormControl,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
    Typography,
    LinearProgress,
    FormControlLabel,
    Switch,
    Tooltip
} from "@material-ui/core";
import {productAdd, productUpdate} from '../../actions';
import DropZone from '../commons/DropZone';
import {hideDialog} from '../commons/Dialog'

const styles = theme => ({
    container: {
        display: 'flex',
        width:'100%',
        flexGrow:1,
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:theme.spacing(1)
    },
    inline:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        flexGrow: 1,
        flexDirection: 'row'
    },
    spinner:{
        // marginLeft: theme.spacing.detail * 3,
        // marginLeft: theme.spacing.detail * 3,
        marginBottom: theme.spacing(4),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'46%'
    },
    textField25: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'23%'
    },
    textArea:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'95%'
    },
    oneThirdWidth:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'30%'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop:4,
        width:'46%',
        // flexGrow: 1
    },
    select:{
        //flexGrow: 1,
        //marginLeft: theme.spacing.detail,
        //marginRight: theme.spacing.detail
    }
});

class ProductForm extends Component {

    constructor(props){
        super(props);
        if(props.edit){
            console.log(props.item);
            this.state = {
                ...props.item,
                highlights: props.item.highlights.join('\n'),
                group:props.item.group._id,
                files: [],
                labelWidth: 0
            };
        }else{
            this.state = {
                name:"",
                detail:"",
                summery:{},
                highlights:"",
                duration:"",
                files: [],
                prices:{adult:null, child:null, infant:null, senior:null},
                //qty:"",
                group:"",
                labelWidth: 0,
            };
        }
    }
    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }

    _handleChange = field => event =>{
        this.setState({[field]: event.target.value});
    };
    _handleSummeryChange = field => event =>{
        this.setState({
            summery:{
                ...this.state.summery,
                [field]: !this.state.summery[field]
            }
        })
    }
    _handlePassengersPriceChange = field => event=>{
        this.setState({
                prices: {
                    ...this.state.prices,
                    [field]: event.target.value === "" ? null : parseInt(event.target.value)
                }
            })
    };
    _onUpdateFiles=(files)=> {
        this.setState({
            files: files
        });
    }
    _save=()=> {
        console.log(this.state);
        const {files, name, /*qty,*/ prices, duration, highlights, summery, detail, group} = this.state;

        this.setState({
            err_name: name.trim().length < 2
        });
        this.setState({
            err_price: parseInt(prices.adult) === 0
        });
        this.setState({
            err_duration: duration.trim().length < 1
        });
        this.setState({
            err_group: group.trim().length < 5
        });

        if(this.props.edit) {
            if (name.trim().length > 0 && duration.trim().length > 0 && parseInt(prices.adult) > 0) {
                this.props.productUpdate(this.props.item._id, name, prices, duration, /*qty,*/ group, detail, summery, highlights.split('\n'), files[0]);
            }
        }
        else {
            this.setState({err_files: files.length === 0});
            if (files.length > 0 && name.trim().length > 0 && duration.trim().length > 0 && parseInt(prices.adult) > 0){
                this.props.productAdd(name, prices, duration, /*qty,*/ group, detail, summery, highlights.split('\n'), files[0]);
            }
        }

    }

    renderActionsButton(){
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._save} size="large" color="primary">
                        Save
                    </Button>
                    <Button onClick={hideDialog} size="large" color="secondary">
                        Dismiss
                    </Button>
                </div>
            );
        }
    }
    render() {
        const {classes, groups} = this.props;
        const {
            name, prices, duration, detail, highlights, group, err_name, err_detail, summery,
            err_duration, err_highlights, err_price, err_files, err_group} = this.state;

        return (
            <DialogContent>
                <Typography gutterBottom variant="h5" component="h3">
                    New Product
                </Typography>
                <Divider/>
                <form className={classes.container} noValidate autoComplete="on">
                    <div className={classes.inline}>
                        <TextField
                            id="outlined-name"
                            label="Name"
                            error={err_name}
                            required
                            className={classes.oneThirdWidth}
                            value={name}
                            onChange={this._handleChange('name')}
                            margin="normal"
                            variant="outlined"
                        />
                        <Tooltip title="Example: 3h 3m or 1 day" aria-label="add">
                            <TextField
                                id="duration"
                                label="Duration"
                                value={duration}
                                onChange={this._handleChange('duration')}
                                type="string"
                                className={classes.oneThirdWidth}
                                margin="normal"
                                variant="outlined"
                                error={err_duration}
                                required
                            />
                        </Tooltip>
                        <FormControl variant="outlined"
                                     className={classes.oneThirdWidth}
                                     error={err_group}
                                     required>
                            <InputLabel
                                ref={ref => {this.InputLabelRef = ref;}}
                                htmlFor="outlined-group-simple"
                            >
                                Group
                            </InputLabel>
                            <Select
                                value={group}
                                className={classes.select}
                                onChange={this._handleChange('group')}
                                input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                        name="group"
                                        id="outlined-group-simple"
                                    />
                                }
                            >                            {
                                groups.map(item=>{
                                    return <MenuItem value={item._id} key={item._id}>{item.name}</MenuItem>
                                })
                            }
                            </Select>
                        </FormControl>

                    </div>
                    <TextField
                        id="outlined-detail"
                        label="Itinerary"
                        className={classes.textArea}
                        value={detail}
                        multiline={true}
                        rows={5}
                        onChange={this._handleChange('detail')}
                        margin="normal"
                        variant="outlined"
                        //error={err_detail}
                        //required
                    />
                    <Tooltip title="Place each item in one line" aria-label="add">
                        <TextField
                            id="outlined-detail"
                            label="Highlights"
                            className={classes.textArea}
                            value={highlights}
                            multiline={true}
                            rows={5}
                            onChange={this._handleChange('highlights')}
                            margin="normal"
                            variant="outlined"
                            error={err_highlights}
                            //required
                        />
                    </Tooltip>
                    <div style={{width:'100%', height:1, backgroundColor:'#479bcb'}}/>
                    <Tooltip title={"Leave empty if its not allowed, put 0 if it's free"}>
                        <h3 style={{margin:8, color:'#479bcb'}}>Prices</h3>
                    </Tooltip>
                    <div className={classes.inline}>
                        <TextField
                            label="adult"
                            error={err_price}
                            required
                            className={classes.textField25}
                            value={prices.adult}
                            onChange={this._handlePassengersPriceChange('adult')}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="senior"
                            value={prices.senior}
                            onChange={this._handlePassengersPriceChange('senior')}
                            type="number"
                            className={classes.textField25}
                            margin="normal"
                            variant="outlined"
                            error={err_price}
                        />
                        <TextField
                            label="child"
                            value={prices.child}
                            onChange={this._handlePassengersPriceChange('child')}
                            type="number"
                            className={classes.textField25}
                            margin="normal"
                            variant="outlined"
                            error={err_price}
                        />
                        <TextField
                            label="infant"
                            value={prices.infant}
                            onChange={this._handlePassengersPriceChange('infant')}
                            type="number"
                            className={classes.textField25}
                            margin="normal"
                            variant="outlined"
                            error={err_price}
                        />
                    </div>
                    <div style={{width:'100%', height:1, backgroundColor:'#479bcb'}}/>
                    <h3  style={{margin:8, color:'#479bcb'}}>Features</h3>
                    <div style={{display:'-webkit-inline-box'}}>
                        <div style={{display:'grid'}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={summery.freeCancellation}
                                        onChange={this._handleSummeryChange('freeCancellation')}
                                        color="primary"
                                    />
                                }
                                label="Free cancellation available"
                            />
                            <FormControlLabel
                                    control={
                                        <Switch
                                            checked={summery.eVoucher}
                                            onChange={this._handleSummeryChange('eVoucher')}
                                            color="primary"
                                        />
                                    }
                                    label="E-voucher(printout not required)"
                                />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={summery.english}
                                        onChange={this._handleSummeryChange('english')}
                                        color="primary"
                                    />
                                }
                                label="Offered in: English"
                            />
                        </div>
                        <div style={{display:'grid'}}>
                            <FormControlLabel
                            control={
                                <Switch
                                    checked={summery.muliLang}
                                    onChange={this._handleSummeryChange('muliLang')}
                                    color="primary"
                                />
                            }
                            label="Offered in: multipleLanguage"
                        />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={summery.noBookingFee}
                                        onChange={this._handleSummeryChange('noBookingFee')}
                                        color="primary"
                                    />
                                }
                                label="No Booking Fees"
                            />
                            <FormControlLabel
                            control={
                                <Switch
                                    checked={summery.picksUp}
                                    onChange={this._handleSummeryChange('picksUp')}
                                    color="primary"
                                />
                            }
                            label="Picks up from select hotels"
                        />
                        </div>
                    </div>
                </form>
                <DropZone
                    error={err_files}
                    onupdatefiles={this._onUpdateFiles}/>
                {this.renderActionsButton()}
            </DialogContent>
        );
    }
}

const mapStateToProps = state => {

    const {groups} = state.groups;
    const {loading} = state.general;
    return {loading,groups};
};

export default connect(mapStateToProps, {productAdd, productUpdate})(withStyles(styles)(ProductForm));
