import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/icons/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        // transform: "translateY(-50%)"
    },
    Icon: {
        color: "#000"
    }
};

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin:theme.spacing(5),
        alignItems:'center',
        alignContents:'center'
    },
    inline:{
        display: 'flex',
        // flexWrap: 'wrap',
        // flexGrow: 1,
        flexDirection: 'row',
        alignItems:'center',
        alignContents:'center'
    },
    spinner:{
        // marginLeft: theme.spacing.unit * 3,
        // marginLeft: theme.spacing.unit * 3,
        marginBottom: theme.spacing(4)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 550,
        overflowY:'auto',
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        flexGrow: 1
    },
    select:{
        // width:150
        //flexGrow: 1,
        //marginLeft: theme.spacing.unit,
        //marginRight: theme.spacing.unit
    }
});

class ToolbarSelect extends React.Component {

    constructor(props){
        super(props);
    }

    render() {
        const { classes, onClick, selectedRows } = this.props;
        return (
            <div className={"custom-toolbar-select"}>
                <Tooltip title={"Edit Order Status"}>
                    <IconButton className={classes.iconButton} onClick={()=>{onClick(selectedRows);}}>
                        <Icon className={classes.Icon} />
                    </IconButton>
                </Tooltip>
            </div>
        );
    }
}

export default withStyles(defaultToolbarSelectStyles, {name: "ToolbarSelect"})(ToolbarSelect);
