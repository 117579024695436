import React, { Component , Fragment} from 'react';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {CssBaseline} from '@material-ui/core'
import { BrowserRouter as Router } from "react-router-dom";
import {persistor, store} from "./store";
import Notifier from './components/commons/Notifier';
import AlertDialog from './components/commons/AlertDialog';
import Dialog from './components/commons/Dialog';
import MainContent from './components/MainContent';
import './App.css';
import {askForPermissioToReceiveNotifications} from './Utils/fcm'
let getDimension, isSmallWindow;

class App extends Component {

    constructor(props){
        super(props);
        setTimeout(askForPermissioToReceiveNotifications, 5000);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
    }
    componentDidMount() {
        this.updateWindowDimensions();
        getDimension = this.getDimension;
        isSmallWindow = this.isSmallWindow;
        window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    }
    updateWindowDimensions() {
        // this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    getDimension = () => {
        return { width: window.innerWidth, height: window.innerHeight };
    };
    isSmallWindow = () => {
        return (window.innerWidth <= 768);
    };

    render() {
        return(
            <Provider store={store}>
                {/*<PersistGate loading={this.renderLoading()} persistor={persistor}>*/}
                <PersistGate persistor={persistor}>
                    <Router basename={'panel'}>
                        <Fragment>
                          <CssBaseline>
                              <MainContent class={'container'}/>
                              <Notifier/>
                              <AlertDialog/>
                              <Dialog/>
                          </CssBaseline>
                        </Fragment>
                    </Router>
                </PersistGate>
            </Provider>
        );
      }

}
export {getDimension, isSmallWindow}
export default App;
