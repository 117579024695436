import React, { Component} from 'react';
import {connect} from 'react-redux';
import Base from './Drawer/Base';
import {validateToken} from '../actions';
import Login from './Login/Login';

class MainContent extends Component {

    constructor(props){
        super(props);
        props.validateToken();
    }
    isLoggedIn() {
        const {user} = this.props;
        return (user && user.token && user.isAdmin);

    }
    render(){
        if(this.isLoggedIn()){
            return <Base/>;
        }
        else {
            return <Login/>;
        }
    }
}
const mapStateToProps = state => {
    const {user} = state.auth;
    return{user};
};

export default connect(mapStateToProps, {validateToken})(MainContent);
