import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Divider from '@material-ui/core/Divider';
import DropZone from '../commons/DropZone';
import {hideDialog} from '../commons/Dialog'
import {Button, Typography} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import {sendAll, sendAdmins} from '../../actions';
import CardActions from '@material-ui/core/CardActions';
import {DialogContent} from "@material-ui/core/es/index";

class Notification extends Component {

    constructor(props){
        super(props);
        this.state = {
                title:'',
                body:'',
                files: []
            };
        this._handleChange  = this._handleChange.bind(this);
        this._onUpdateFiles = this._onUpdateFiles.bind(this);
        this._send          = this._send.bind(this);
        this._sendToAdmins          = this._sendToAdmins.bind(this);
        this.setError       = this.setError.bind(this);
    }
    _handleChange = field => event =>{
        switch (field){
            case 'title':{
                this.setState({
                    ...this.state,
                    title: event.target.value
                });
                break;
            }
            case 'body' :{
                this.setState({
                    ...this.state,
                    body: event.target.value
                });
                break;
            }
        }
        //console.log(this.state);
    };
    _onUpdateFiles(files) {
        //console.log(files);
        this.setState({
            files: files
        });
    }
    setError(error){
        this.setState((state,props)=>({
            error : {...state.error, error}
        }));
    }
    _send(){
        //console.log('edit');
        const {files, title, body} = this.state;
        this.setState({err_title: title.trim().length < 2});
        this.setState({err_body: body.trim().length < 2 ? 'message is empty':''});
        this.setState({err_files: null});

        if(title.trim().length > 2 && body.trim().length > 2){
            this.props.sendAll(title, body, files[0]);
        }
    }
    _sendToAdmins(){
        const {files, title, body} = this.state;
        this.setState({err_title: title.trim().length < 2});
        this.setState({err_body: body.trim().length < 2 ? 'message is empty':''});
        this.setState({err_files: null});

        if(title.trim().length > 2 && body.trim().length > 2){
            this.props.sendAdmins(title, body, files[0]);
        }
    }


    renderActionsButton(){
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._send} size="large" style={{color:'#46793c'}}>
                        Send (All user)
                    </Button>
                    <Button onClick={this._sendToAdmins} size="small" color="primary">
                        Send (only Admins)
                    </Button>
                    <Button onClick={hideDialog} size="small" color="secondary">
                        Dismiss
                    </Button>
                </div>
            );
        }
    }
    render() {
        const {classes} = this.props;
        const {title,body, err_title, err_body, err_files} = this.state;
        return (
            <DialogContent>
                <Typography gutterBottom variant="h5" component="h3">
                    Send Notification
                </Typography>
                <Divider/>
                <form className={classes.container} autoComplete="on">
                    <TextField
                        id="outlined-title"
                        label="Title"
                        error={err_title}
                        className={classes.textField}
                        value={title}
                        onChange={this._handleChange('title')}
                        margin="normal"
                        variant="outlined"
                        required
                    />
                    <TextareaAutosize
                            id="outlined-body"
                            label="Message"
                            placeholder="Message"
                            style={{width:400, height:200, borderRadius:5}}
                            className={classes.textField}
                            value={body}
                            rows={5}
                            rowsMax={10}
                            onChange={this._handleChange('body')}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                    <p style={{fontSize:'12', color:'#f44336', height:20}}>{err_body}</p>
                </form>
                <DropZone
                    error={err_files}
                    onupdatefiles={this._onUpdateFiles}/>
                <CardActions>
                    {this.renderActionsButton()}
                </CardActions>
            </DialogContent>
        );
    }
}

const styles = theme => ({
    // container: {
    //     display: 'flex',
    //     flexDirection:'column',
    //     flexWrap: 'wrap',
    //     margin:theme.spacing(5),
    //     marginBottom:0,
    //     alignItems:'center',
    //     alignContents:'center'
    // },
    container: {
        display: 'flex',
        width:'100%',
        flexGrow:1,
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:theme.spacing(1)
    },
    inline:{
        display: 'flex',
        // flexWrap: 'wrap',
        // flexGrow: 1,
        flexDirection: 'row',
        alignItems:'center',
        alignContents:'center'
    },
    spinner:{
        // marginLeft: theme.spacing.unit * 3,
        // marginLeft: theme.spacing.unit * 3,
        marginBottom: theme.spacing(4)
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'46%'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 550,
        overflowY:'auto',
    },
});
const mapStateToProps = state => {
    const {loading} = state.general;
    return {loading};
};

export default connect(mapStateToProps, {sendAll, sendAdmins})(withStyles(styles)(Notification));
