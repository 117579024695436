import {getUser} from "./Auth";
import * as types from "./types";
import NotificationApi from "../api/Notification";
import {BrokenConnection, SessionExpired} from "../helpers";
import {hideDialog} from "../components/commons/Dialog";

export const sendAll    = (title, body, file)=>{
    return((dispatch) => {
        dispatch({type: types.START_LOADING});
           dispatch(getUser())
                .then((user)=>{
                NotificationApi.sendAll(user.token, title, body, file).then(
                    result => {
                        dispatch({type: types.NOTIFICATION_SEND_ALL_SUCCESS, payload: result.data});
                        dispatch({type: types.STOP_LOADING});
                        hideDialog();
                    }
                )
                .catch((err)=>{
                    BrokenConnection(err, dispatch);
                });
            })
            .catch(()=>{
                SessionExpired(dispatch);
            });
    });
};
export const sendAdmins = (title, body, file)=>{
    return((dispatch) => {
        dispatch({type: types.START_LOADING});
           dispatch(getUser())
                .then((user)=>{
                NotificationApi.sendAdmins(user.token, title, body, file).then(
                    result => {
                        dispatch({type: types.NOTIFICATION_SEND_ALL_SUCCESS, payload: result.data});
                        dispatch({type: types.STOP_LOADING});
                        hideDialog();
                    }
                )
                .catch((err)=>{
                    BrokenConnection(err, dispatch);
                });
            })
            .catch(()=>{
                SessionExpired(dispatch);
            });
    });
};
