import {combineReducers} from 'redux';
import AuthReducer from './AuthReducer';
import ProductReducer from './ProductReducer';
import CustomersReducer from './CustomersReducer';
import GroupReducer from './GroupReducer';
import OrderReducer from './OrderReducer';
import TimeTableReducer from './TimeTableReducer';
import GalleryReducer from './GalleryReducer';
import GeneralReducer from './GeneralReducer';
import CouponsReducer from './CouponsReducer';
import ServiceReducer from './ServiceReducer';

export default combineReducers({
    auth: AuthReducer,
    products:  ProductReducer,
    customers: CustomersReducer,
    groups: GroupReducer,
    orders: OrderReducer,
    timeTable: TimeTableReducer,
    gallery:  GalleryReducer,
    general:  GeneralReducer,
    coupons:  CouponsReducer,
    services: ServiceReducer
});