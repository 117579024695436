import {getUser} from "./Auth";
import * as types from "./types";
import OrderApi from "../api/Order";
import {BrokenConnection, SessionExpired} from "../helpers";
import {hideDialog} from "../components/commons/Dialog";
import {openSnackbar} from "../components/commons/Notifier";

export const ordersFetch = (page, limit, sort, dir, from, to, mode)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('here');
                    OrderApi.get(user.token, page, limit, sort, dir, from, to, mode).then(
                        result => {
                            dispatch({type: types.ORDERS_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch(err=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    dispatch({type: types.LOGOUT_USER});
                });
        }
    );
};
export const ordersGetById = (id)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    OrderApi.getById(user.token, id).then(
                        result => {
                            dispatch({type: types.ORDER_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    dispatch({type: types.LOGOUT_USER});
                });
        }
    );
};
export const ordersSearch = (query, page, limit, sort, dir, from, to, mode)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    OrderApi.search(user.token, query, page, limit, sort, dir, from, to, mode).then(
                        result => {
                            dispatch({type: types.ORDER_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const updateOrderStatus =(ids, status, comment)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    OrderApi.update(user.token, ids.join(','), status, comment).then(
                        () => {
                            dispatch({type: types.ORDER_UPDATE_SUCCESS, payload: ids, status});
                            dispatch({type: types.STOP_LOADING});
                            hideDialog();
                            openSnackbar(`${ids.length} order status changed successfully to ${status}`, 2500);
                        }
                    )
                    .catch((err)=>{
                        console.log(err);
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch((err)=>{
                    console.log(err);
                    dispatch({type: types.LOGOUT_USER});
                });
        }
    );
};
