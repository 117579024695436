export let SERVICES_UPDATE_SUCCESS = "SERVICES_UPDATE_SUCCESS";
export let SERVICES_ADD_SUCCESS = "SERVICES_ADD_SUCCESS";
export let SERVICES_DELETE_SUCCESS = "SERVICES_DELETE_SUCCESS";
export let SERVICES_FETCH_SUCCESS = "SERVICES_FETCH_SUCCESS";

export let COUPON_UPDATE_SUCCESS = "COUPON_UPDATE_SUCCESS";
export let COUPON_ADD_SUCCESS = "COUPON_ADD_SUCCESS";
export let COUPONS_FETCH_SUCCESS = "COUPONS_FETCH_SUCCESS";
export let COUPON_DELETE_SUCCESS = "COUPON_DELETE_SUCCESS";

export let ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export let NOTIFICATION_SEND_ALL_SUCCESS = "NOTIFICATION_SEND_ALL_SUCCESS";
export let PRODUCTS_UPDATE_SUCCESS = "PRODUCTS_UPDATE_SUCCESS";
export let STOP_LOADING = "STOP_LOADING";
export let START_LOADING = "START_LOADING";

export let GALLERY_ADD_SUCCESS = "GALLERY_ADD_SUCCESS";
export let GALLERY_DELETE_SUCCESS = "GALLERY_DELETE_SUCCESS";
export let GALLERY_FETCH_SUCCESS = "GALLERY_FETCH_SUCCESS";

export let TIME_TABLE_ITEM_ADD_SUCCESS = "TIME_TABLE_ITEM_ADD_SUCCESS";
export let TIME_TABLE_ITEM_DELETE_SUCCESS = "TIME_TABLE_ITEM_DELETE_SUCCESS";
export let CURRENT_PROGRAM_FETCH_SUCCESS = "CURRENT_PROGRAM_FETCH_SUCCESS";
export let TIME_TABLE_FETCH_SUCCESS = "TIME_TABLE_FETCH_SUCCESS";

export const BROKEN_CONNECTION = "ORDER_FETCH_SUCCESS";
export const CUSTOMERS_FETCH_SUCCESS = "CUSTOMERS_FETCH_SUCCESS";

export const ADD_ORDER_SUCCESS    = "ADD_ORDER_SUCCESS";
export const ORDERS_FETCH_SUCCESS = "ORDERS_FETCH_SUCCESS";
export const ORDER_FETCH_SUCCESS  = "ORDER_FETCH_SUCCESS";

export const SET_CART_DELIVERY_DATE  = "SET_CART_DELIVERY_DATE";
export const SET_CART_DELIVERY_TIME  = "SET_CART_DELIVERY_TIME";
export const SET_CART_DELIVERY_TYPE  = "SET_CART_DELIVERY_TYPE";
export const SET_CART_PAYMENT_METHOD = "SET_CART_PAYMENT_METHOD";

export const EMAIL_CHANGED    = 'EMAIL_CHANGED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

export const LOGIN_USER_SUCCESS  = "LOGIN_USER_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const LOGIN_USER_FAILED  = "LOGIN_USER_FAILED";
export const LOGIN_USER  = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER    = "GET_USER";

export const GROUP_FETCH_SUCCESS  = "GROUP_FETCH_SUCCESS";
export const GROUP_DELETE_SUCCESS = "GROUP_DELETE_SUCCESS";
export const GROUP_DELETE_FAILED = "GROUP_DELETE_FAILED";
export const GROUP_ADD_SUCCESS = "GROUP_ADD_SUCCESS";
export const GROUP_ADD_FAILED  = "GROUP_ADD_FAILED";
export const GROUP_UPDATE_SUCCESS = "GROUP_UPDATE_SUCCESS";
export const GROUP_PRODUCTS_UPDATE_SUCCESS = "GROUP_PRODUCTS_UPDATE_SUCCESS";
export const GROUP_PRODUCTS_FETCH_SUCCESS = "GROUP_PRODUCTS_FETCH_SUCCESS";

export const PRODUCTS_ADD_FAILED = "PRODUCTS_ADD_FAILED";
export const PRODUCTS_ADD_SUCCESS = "PRODUCTS_ADD_SUCCESS";
export const PRODUCTS_FETCH_SUCCESS = "PRODUCTS_FETCH_SUCCESS";
export const PRODUCTS_DELETE_SUCCESS = "PRODUCTS_DELETE_SUCCESS";

export const CART_ADD = "ADD_TO_CART";
export const CART_REMOVE = "CART_REMOVE";
export const CART_CLEAR = "CART_CLEAR";
export const CART_INCREASE_NUMBER = "CART_INCREASE_NUMBER";
export const CART_DECREASE_NUMBER = "CART_DECREASE_NUMBER";

export const SET_CART_ADDRESS = "SET_CART_ADDRESS";
export const GET_ADDRESSES = "GET_ADDRESSES";

export const ADD_ADDRESSES_SUCCESS = "ADD_ADDRESSES_SUCCESS";
export const REMOVE_ADDRESS_SUCCESS = "REMOVE_ADDRESS_SUCCESS";