import React, {Component} from "react";
import {connect} from 'react-redux';
import MUIDataTable from "mui-datatables";
import {customersFetch, customersSearch} from '../../actions';
import {LinearProgress} from "@material-ui/core/es/index";
import '../../App.css';
import {MuiTableWrapper} from "../commons/MuiTableWrapper";

class CustomersList extends Component {

    constructor(props){
        super(props);
        this.props.customersFetch(1,10);
        this._onTableChange = this._onTableChange.bind(this);
        this._onRowClick = this._onRowClick.bind(this);
    }

    prepareData(items){
        console.log(items);
        if(!items)
            return [];
        return items.map((item)=>{
            let rule = "Customer";
            if(item.isAdmin){
                rule = "Admin";
            }
            return {...item, rule};
        });
    }

    //Table Actions
    _onRowClick(rowData, rowMeta){
        //console.log('onRowClick');
        //console.log(rowData);
        //console.log(rowMeta);
    }
    _onTableChange(action, tableState){
        //console.log('_onTableChange');
        //console.log(action);
        if(action === 'sort' || action !== 'propsUpdate' || action === 'search') {
            // if (action === 'rowsSelect') return;
            //console.log(tableState);

            const {page, rowsPerPage, searchText, activeColumn, columns} = tableState;
            let sort = null;
            let sortDir = null;
            //console.log(activeColumn);

            if (activeColumn !== null) {
                sort = columns[activeColumn].name;
                sortDir = columns[activeColumn].sortDirection;
                sort = sort === 'rule' ? 'isAdmin' : sort;
            }

            if (searchText) {
                this.props.customersSearch(searchText, page + 1, rowsPerPage, sort, sortDir);
            }
            else {
                this.props.customersFetch(page + 1, rowsPerPage, sort, sortDir);
            }
        }
    }
    _onRowsDelete(rowsDeleted){
        //console.log('delete--->');
        //console.log(rowsDeleted);
    }

    renderLoading(loading){
        if(loading){
            return(
                <LinearProgress style={{color:'#4692e7'}}/>
            )
        }else{
            return(<view></view>);
        }
    }
    render() {
        const {user} = this.props;
        const {items, count} = this.props.customers;
        const columns = [
            {
                name:"name",
                label:"Name",
                options: {
                    filter: true,
                    sort:   true,
                }
            },
            {
                name:"email",
                label:"Email",
                options: {
                    filter: true,
                    sort:   true,
                }
            },
            {
                name:"phone",
                label:"Phone",
                options: {
                    filter: true,
                    sort:   true,
                }
            },
            {
                name:"status",
                label:"Status",
                options: {
                    filter: true,
                    sort:   true,
                }
            },
            {
                name:"rule",
                label:"Rule",
                options: {
                    filter: true,
                    sort: true,
                }
            }
        ];
        const options = {
            // filterType: "textField",
            filter:false,
            responsive: "scroll",
            serverSide: true,
            selectableRows: false,
            fixedHeader:false,
            count: count,
            onTableChange: this._onTableChange,
            onRowClick:    this._onRowClick,
            onRowsDelete: this._onRowsDelete,
            // onColumnSortChange: this._onColumnSortChange
        };
        if(user.accessLevel.customers.read) {
            return (
                <MuiTableWrapper>
                    {this.renderLoading(this.props.loading)}
                    <MUIDataTable
                        title={"Customers"}
                        data={this.prepareData(items)}
                        columns={columns}
                        options={options}
                    />
                </MuiTableWrapper>
            );
        }else {
            return <strong style={{fontSize:32}}>403 Access Denied</strong>
        }
    }
}

const mapStateToProps = state => {
    const {user} = state.auth;
    const {loading} = state.general;
    const {customers} = state.customers;
    return {customers, loading, user};
};

export default connect(mapStateToProps,{customersFetch, customersSearch})(CustomersList);
