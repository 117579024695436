import axios from 'axios';
import Config from '../Config';

class OrderApi {
    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'order',
            timeout: Config.TIME_OUT,
        });
    }
    get(user, page, limit, sort=null, dir=null, from=null, to=null, mode=null) {
        let url = `?page=${page}&limit=${limit}`;
        if(sort){
            url += `&sort=${sort}:${dir}`
        }
        if(from && to){
            url += `&from=${from}&to=${to}`
        }
        if(mode){
            url += `&mode=${mode}`;
        }
        return this.instance({
            method: 'get',
            url,
            headers: {'x-auth-token': user}
        });
    }
    getById(user,id){
        return this.instance({
            method: 'get',
            url: `/${id}`,
            headers: {'x-auth-token': user}
        });

    }
    search(user, query, page, limit, sort=null, dir=null, from=null, to=null, mode=null) {
        let url = `?page=${page}&limit=${limit}&query=${query}`;
        if(sort){
            url += `&sort=${sort}:${dir}`
        }
        if(from && to){
            url += `&from=${from}&to=${to}`
        }
        if(mode){
            url += `&mode=${mode}`;
        }
        return this.instance({
            method: 'get',
            url,
            headers: {'x-auth-token': user}
        });
    }
    update(user, ids, status, comment) {
        return this.instance({
            method: 'put',
            url: `/${ids}`,
            headers: {'x-auth-token': user},
            data:{
                status,
                comment
            }
        });
    }
}

export default new OrderApi();