import React from "react";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {getDimension, isSmallWindow} from "../../App";
const muiTheme = createMuiTheme({
    overrides: {
        MUIDataTable: {
            paper: {
                height: 'inherit',
            },
            responsiveScroll: {
                // maxHeight: 'none',
                height: 'calc(100% - 128px)'
            }
        }
    }
});


export function MuiTableWrapper({children}) {

    // let height = isSmallWindow()? getDimension().height - 64: getDimension().height - '100%';
    // console.log('isSmallWindow', isSmallWindow());
    let height = getDimension().height - 64;

    if(isSmallWindow()){
        return(
            <view style={{height}}>
                <MuiThemeProvider theme={muiTheme}>
                    {children}
                </MuiThemeProvider>
            </view>
        )
    }
    else {
        return  children;
    }

}