import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Button, Typography, DialogContent, TextField, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import {hideDialog} from '../commons/Dialog'
import LinearProgress from '@material-ui/core/LinearProgress';
import {updateOrderStatus} from '../../actions';
import CardActions from '@material-ui/core/CardActions';

const styles = theme => ({
    container: {
        display: 'flex',
        width:'100%',
        flexGrow:1,
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:theme.spacing(1)
    },
    inline:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        flexGrow: 1,
        flexDirection: 'row'
    },
    spinner:{
        // marginLeft: theme.spacing.detail * 3,
        // marginLeft: theme.spacing.detail * 3,
        marginBottom: theme.spacing(4),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'45%'
    },
    textArea:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'100%'
    },
    formControl: {
        // margin: theme.spacing(1),
        marginTop:4,
        width:'50%',
        // flexGrow: 1
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    select:{
        //flexGrow: 1,
        //marginLeft: theme.spacing.detail,
        //marginRight: theme.spacing.detail
    }
});

class OrderStatusChangerForm extends Component {

    constructor(props){
        super(props);
        const order = this.getOrder(props.orderId);
        console.log('order', order);
        this.state = {
            order,
            status: order.status,
            comment: order.comment ? order.comment : ""
        };
    }
    getOrder=(_id)=>{
        const {items} = this.props.orders;
        for(let i = 0 ; i < items.length ; i++){
            console.log('items', items);
            console.log('_id', _id);
            if(items[i]._id === _id){
                return items[i];
            }
        }
        return {};
    };
    formValidatioin= () =>{
        const {comment} = this.state;
        const err_comment = comment.length < 3;
        this.setState({err_comment});
        return !(err_comment);
    }
    _handleChange = field => event =>{
        this.setState({[field]: event.target.value});
    };
    _handleStatusChange = (event, status)=>{
        this.setState({status})
        console.log(this.state);
    }
    _save=()=>{
        if(this.formValidatioin()){
            this.props.updateOrderStatus([this.props.orderId],this.state.status, this.state.comment);
        }
    };

    renderActionsButton=()=>{
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._save} size="large" color="primary">
                        Save
                    </Button>
                    <Button onClick={hideDialog} size="large" color="secondary">
                        Dismiss
                    </Button>
                </div>
            );
        }
    }
    render(){
        const {classes} = this.props;
        const {comment, err_comment} = this.state;
        return (
            <DialogContent>
                <Typography gutterBottom variant="h5" component="h3">
                    New Group
                </Typography>
                <Divider/>
                <form className={classes.container} autoComplete="on">
                    <TextField
                        id="outlined-detail"
                        label="Description"
                        className={classes.textArea}
                        value={comment}
                        multiline={true}
                        error={err_comment}
                        rows={3}
                        onChange={this._handleChange('comment')}
                        margin="normal"
                        variant="outlined"
                    />
                    <h3 style={{marginRight: 32}}>Order Status:</h3>
                    <RadioGroup
                        aria-label="quiz"
                        name="Status"
                        row
                        value={this.state.status}
                        onChange={this._handleStatusChange}
                    >
                        <FormControlLabel value="paid" control={<Radio />} label="Paid" />
                        <FormControlLabel value="overdue" control={<Radio />} label="Overdue" />
                        <FormControlLabel value="delivered" control={<Radio />} label="Delivered" />
                        <FormControlLabel value="canceled" control={<Radio />} label="Canceled" />
                    </RadioGroup>
                </form>
                <CardActions>
                    {this.renderActionsButton()}
                </CardActions>
            </DialogContent>
        );
    }
}

const mapStateToProps = state => {
    const {loading} = state.general;
    const {orders} = state.orders;
    return {loading, orders};
};

export default connect(mapStateToProps, {
    updateOrderStatus
})(withStyles(styles)(OrderStatusChangerForm));
