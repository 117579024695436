import * as types from '../actions/types';
import {hideDialog} from "../components/commons/Dialog";


const INITIAL_STATE = {
    timeTable:[],
    currentProgram:[]
};

export default (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case types.TIME_TABLE_FETCH_SUCCESS:
            return {...state, timeTable: action.payload};

        case types.TIME_TABLE_ITEM_ADD_SUCCESS:
            hideDialog();
            const {items, count} = state.timeTable;
            let newState = {...state, timeTable:{ items: [...items, action.payload], count: count+1 }};
            //console.log(newState);
            return newState;

        case types.TIME_TABLE_ITEM_DELETE_SUCCESS:
            let timeTables = [];
            //console.log(state.timeTable);
            state.timeTable.items.forEach(item=>{
                if(item._id !== action.payload){
                    timeTables.push(item);
                }
            });
            return {...state, timeTable: {items: timeTables, count: timeTables.length}};

        case types.CURRENT_PROGRAM_FETCH_SUCCESS:
            return {...state, currentProgram: action.payload};

        default:
            return state;
    }
}