import axios from 'axios';
import Config from '../Config';

class CouponsApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'coupon',
            timeout: Config.TIME_OUT,
        });
    }

    get(user, page, limit, sort=null, dir=null) {
        let url = `?page=${page}&limit=${limit}`;
        if(sort){
            url += `&sort=${sort}:${dir}`
        }
        return this.instance({
            method: 'get',
            url,
            headers: {'x-auth-token': user}
        });
    }
    add(user, code, expireDate, value) {
        return this.instance({
            method: 'post',
            url:'/',
            headers: {'x-auth-token': user},
            data:{code, expireDate, value}
        });
    }
    delete(user, id) {
        return this.instance({
            method: 'delete',
            url: `/${id}`,
            headers: {'x-auth-token': user}
        });
    }
    update(user, _id, code, expireDate, value, status) {
        return this.instance({
            method: 'put',
            url:`/${_id}`,
            headers: {'x-auth-token': user},
            data:{code, expireDate, value, status}
        });
    }
}
export default new CouponsApi();