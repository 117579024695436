import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import './DropZone.css';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateSize);

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin:theme.spacing(5)
    },
    error:{
        color:'#f44336'
    }
});

class DropZone extends Component {

    constructor(props){
        super(props);
        this.state = {
            files: []
        };
        this._onUpdateFiles = this._onUpdateFiles.bind(this);
    }
    _onUpdateFiles(fileItems) {
        let files = [];
        fileItems.forEach(item=>{
            if(item.status !== 8)
                files.push(item.file);
        });
        this.setState({
            files
        });

        this.props.onupdatefiles(files);
    }


    renderError(){
        const {classes} = this.props;
        if(this.props.error && this.state.files.length === 0){
            return <p className={classes.error}>*Image is required</p>;
        }
        return <div/>;
    }
    render() {
        let {maxFiles} = this.props;
        if(!maxFiles)
            maxFiles = 1;
        return (
            <div>
                {this.renderError()}
                <FilePond
                    maxFileSize={"5MB"}
                    acceptedFileTypes={['image/jpeg','image/jpg','image/png']}
                    ref={ref => (this.pond = ref)}
                    files={this.state.files}
                    allowMultiple={this.props.allowMultiple}
                    //className={classes.error}
                    maxFiles={maxFiles}
                    onupdatefiles={this._onUpdateFiles}
                />
            </div>
        );
    }
}

export default withStyles(styles)(DropZone);
// export default DropZone;
