import axios from 'axios';
import Config from '../Config';

class CustomersApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'users',
            timeout: Config.TIME_OUT,
        });
    }
    get(user, page, limit, sort, dir) {
        let url = `/?page=${page}&limit=${limit}`;
        if(sort){
            url += `&sort=${sort}:${dir}`;
        }
        return this.instance({
            method: 'get',
            url: url,
            headers: {'x-auth-token': user}
        });
    }
    search(user, query, page, limit, sort, dir) {
        let url = `/?page=${page}&limit=${limit}&query=${query}`;
        if(sort){
            url += `&sort=${sort}:${dir}`;
        }
        return this.instance({
            method: 'get',
            url: url,
            headers: {'x-auth-token': user}
        });
    }
}
export default new CustomersApi();