import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {productUpdateStatus, groupsFetch, getProducts} from '../../actions';
import Card from '@material-ui/core/Card';
import {getFullUrl} from '../../Utils/image';
import {Tooltip, Typography} from "@material-ui/core";
import {LinearProgress} from "@material-ui/core/es/index";

class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state={
            selectedItems:[]
        };
        this.props.groupsFetch((data,err)=>{
            if(err !== null){
                if(data) {
                    data.forEach(group => {
                        if (group.type === 'timed') {
                            this.props.getProducts(group._id);
                        }
                    })
                }
            }
        });
        this.renderProducts = this.renderProducts.bind(this);
        this._onClickProduct = this._onClickProduct.bind(this);
    }
    _onClickProduct(product){
        if(product.status === 'active') {
            this.props.productUpdateStatus(product._id, 'baking');
            this.setState({
                ...this.state,
                [product._id]:'#56b75c'
            });
        }
        else{
            this.props.productUpdateStatus(product._id, 'active');
            this.setState({
                ...this.state,
                [product._id]:'#FFF'
            });

        }

    }
    renderProducts(products){
        if(products && products.length > 0) {
            return products.map((item) => {
            // const url = config.API_URL + item.thumbnail;
            let color = item.status === 'baking'?'#ffbe42':'transparent';
                return (
                    <div key={item._id} onClick={()=>{this._onClickProduct(item)}} style={{
                            padding:10,
                            margin: 5,
                            borderRadius:20,
                            flexDirection:'column',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'space-around',
                            backgroundColor:color}}>
                        <img src={getFullUrl(item.thumbnail)}
                             height="100"
                             width="100"
                             alt={item.name}
                             style={{borderRadius:10000, objectFit:'cover', margin:10}}/>
                        <p className={this.props.classes.productName}>{item.name}</p>
                        <div style={{fontSize:12, color:'#3e3e3e'}}>{item.detail}</div>
                    </div>
                );
            })
        }else {
            return <view>Empty</view>;
        }
    }
    renderLoading(loading){
        if(loading){
            return(
                <LinearProgress style={{color:'#4692e7'}}/>
            )
        }else{
            return(null);
        }
    }
    renderItem(item, classes){
        return(
            <Card key={item._id} style={{margin:7, display:'flex', alignItem:'center', flexWrap:'noWrap', flexDirection:'column', minWidth:160}}>
                <p style={{alignSelf:'center', marginBottom:0, fontWeight:500, fontSize:18}}>{item.name}</p>
                <div className={classes.groupCard}>
                    {this.renderProducts(item.products)}
                </div>
            </Card>
        );
    }
    render() {
        const {classes, groups} = this.props;
        return(
            <div style={{minWidth:160}}>
                <Tooltip title={"Select products to show as hot in application(only timed products are displayed in this section)"}>
                    <Typography gutterBottom variant="h6" style={{width:250, marginLeft:8}}>

                    </Typography>
                </Tooltip>
                {this.renderLoading(this.props.loading)}
                {/*<div style={{height:2, width:'100%', backgroundColor:'#3f51b5', marginBottom:15, marginTop:-1}}/>*/}
                <div style={{flexWrap:'wrap', display:'flex',flexShrink:1}}>
                    {
                        // groups.filter(item=>{
                        //     return (item.type === 'timed' && item.products && item.products.length > 0);
                        // })
                        // .map(item=>(this.renderItem(item, classes)))
                    }
                </div>
            </div>
        );
    }
}

const styles = theme => ({
    groupCard:{
        padding: 0,
        margin:5,
        flexDirection:'row',
        flexWrap:'wrap',
        alignItems:'center',
        justifyContent:'flex-left',
        display:'flex',
        // overflowX:'auto'
    },
    text:{
        marginLeft:20
    },
    productName:{
      fontSize:16,
      fontWeight:450,
    },
    header:{
        marginBottom:7,
        margin:0
    },
    button: {
        // margin: theme.spacing.unit,
        color: '#56b75c'
    },
    iconButton: {
        marginRight: "24px",
        float:'right',
        top: "50%",
        display: "inline-block",
        position: "relative",
    },
    deleteIcon: {
        color: "#000"
    }
});
const mapStateToProps = state => {
    console.log(state);
    const {groups} = state.groups;
    const {loading} = state.general;
    return {groups, loading};
};
export default connect(mapStateToProps,{productUpdateStatus, groupsFetch, getProducts})(withStyles(styles)(Dashboard));
