export default {
    apiKey: "AIzaSyCLPHXv-xp7Iuf87wvzzmpAIxE4tp7N4ao",
    authDomain: "arian-travel.firebaseapp.com",
    databaseURL: "https://arian-travel.firebaseio.com",
    projectId: "arian-travel",
    storageBucket: "arian-travel.appspot.com",
    messagingSenderId: "993746234465",
    appId: "1:993746234465:web:e7dffca8d90a1360034e20",
    measurementId: "G-LNC9QTCR8G"
};

