import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
let openSnackbarFn;
class Notifier extends React.Component {
    state = {
        open: false,
        message: '',
    };
    componentDidMount() {
        openSnackbarFn = this.openSnackbar;
    }
    openSnackbar = ({message, duration}) => {
        if(!duration)
            duration = 3000;
        this.setState({
            open: true,
            message,
            duration
        });
    };
    handleSnackbarClose = () => {
        this.setState({
            open: false,
            message: '',
        });
    };
    render() {
        const message = ( <span id = "snackbar-message-id" dangerouslySetInnerHTML = {{__html: this.state.message}}/>);
        return (
            <Snackbar
                anchorOrigin = {{vertical: 'top', horizontal: 'center'}}
                message = {message}
                autoHideDuration = {this.state.duration}
                onClose = {this.handleSnackbarClose}
                open = {this.state.open}
                SnackbarContentProps = {{'aria-describedby': 'snackbar-message-id'}}/>
        );
    }
}
export function openSnackbar(message, duration) {
    openSnackbarFn({
        message,
        duration
    });
}

export default Notifier;
