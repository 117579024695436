import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import Moment from 'moment';
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import MUIDataTable from "mui-datatables";
import {couponsFetch, couponDelete} from '../../actions';
import {showAlertDialog} from '../commons/AlertDialog';
import {hideDialog, showDialog} from "../commons/Dialog";
import CouponForm from "./CouponForm";
import CustomToolbarSelect from "../commons/CustomToolbarSelect";
import {LinearProgress} from "@material-ui/core/es/index";
import {getFullUrl} from "../../Utils/image";
import {MuiTableWrapper} from "../commons/MuiTableWrapper";
const styles = theme => ({
    button: {
       // margin: theme.spacing.unit,
        color: '#56b75c'
    }
});

class CouponList extends Component {

    constructor(props){
        super(props);
        this.props.couponsFetch(1,10);
    }

    prepareData(items){
        if(!items)
            return [];
        return items.map((item)=>{
            const moment = new Moment(item.expireDate);
            let expireDate = moment.calendar();
            let status = moment.isAfter(new Date())?item.status:'Expired';
            return {...item, expireDate, status};
        });
    }
    _onTableChange=(action, tableState)=>{
        if(action === 'rowsSelect' || action === 'expandRow') return;
        if(action === 'sort' || action !== 'propsUpdate' || action === 'search') {
            //console.log(tableState);
            this.tableState = tableState;
            const {page, rowsPerPage, searchText, activeColumn, columns} = tableState;
            let sort = null;
            let sortDir = null;
            //console.log(activeColumn);

            if (activeColumn !== null) {
                sort = columns[activeColumn].name;
                sortDir = columns[activeColumn].sortDirection;
                console.log('sortDir',sortDir);
                switch (sort) {
                    case "name":
                        sort = "name";
                        break;
                    case "detail":
                        sort = "detail";
                        break
                }
            }
            if (searchText) {
                //this.props.couponSearch(searchText, page + 1, rowsPerPage, sort, sortDir);
            }
            else {
                this.props.couponsFetch(page + 1, rowsPerPage, sort, sortDir);
            }
        }
    }
    _onRowsDelete=(rowsDeleted)=>{
        const {couponDelete, coupons} = this.props;
        this.setState({data: this.prepareData(coupons.items)});
        let codes = [];
        rowsDeleted.data.forEach((data)=>{
            codes.push(coupons.items[data.index].code);
        });

        showAlertDialog('Attention', `you are about to delete ${codes.join(", ")}.`, ()=>{
            rowsDeleted.data.forEach((data)=>{
                couponDelete(coupons.items[data.index]._id);
            });
        });
        return false;

    }
    _customToolbar=()=>{
        const { classes, user } = this.props;
        if(user.accessLevel.coupons.write) {
            return (
                <IconButton onClick={this._addNew} color="primary" className={classes.button}
                            aria-label="Add New Coupon">
                    <Add/>
                </IconButton>
            );
        }
        else {
            return null;
        }

    }
    _addNew(){
        showDialog(
            <CouponForm/>
        );
    }
    _onRowEdit(selectedRows){
        const {coupons} = this.props;
        //console.log(selectedRows);
        const item = coupons.items[selectedRows.data[0].dataIndex];
        showDialog(
            <CouponForm item={item} edit/>
        );
    }

    renderLoading(loading){
        if(loading){
            return(
                <LinearProgress style={{color:'#4692e7'}}/>
            )
        }else{
            return(<view></view>);
        }
    }
    render() {
        const {user} = this.props;
        const {items, count} = this.props.coupons;
        const columns = [
            {
                name:"code",
                label:"Code"
            },
            {
                name:"value",
                label:"Value"
            },
            {
                name:"expireDate",
                label:"Expire Date"
            },
            {
                name:"status",
                label:"Status"
            }

        ];
        const options = {
            // filterType: "dropdown",
            filter:false,
            responsive: "scroll",
            serverSide: true,
            fixedHeader:false,
            count: count,
            onRowsDelete: this._onRowsDelete,
            onTableChange: this._onTableChange,
            customToolbar: this._customToolbar,
            isRowSelectable: ()=>(user.accessLevel.coupons.write),
            customToolbarSelect: selectedRows => (
                <CustomToolbarSelect selectedRows={selectedRows} onDelete={this._onRowsDelete} onEdit={this._onRowEdit}/>
            )

        };
        if(user.accessLevel.coupons.read) {
            return (
                <MuiTableWrapper>
                    {this.renderLoading(this.props.loading)}
                    <MUIDataTable
                        title={"Coupons"}
                        data={this.prepareData(items)}
                        columns={columns}
                        options={options}/>
                </MuiTableWrapper>
            );
        }else {
            return <strong style={{fontSize:32}}>403 Access Denied</strong>
        }
    }
}

const mapStateToProps = state => {
    const {user} = state.auth;
    const {coupons} = state.coupons;
    const {loading} = state.general;
    return {coupons, loading,user};
};

export default connect(mapStateToProps,{couponsFetch, couponDelete})(withStyles(styles)(CouponList));