import * as types from '../actions/types';
import {hideDialog} from '../components/commons/Dialog';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case types.GALLERY_FETCH_SUCCESS:
            return {...state, gallery: action.payload};

        case types.GALLERY_DELETE_SUCCESS: {
            hideDialog();
            let gallery = [...state.gallery.items];
            // //console.log(state.gallery.items);
            // //console.log('GALLERY_DELETE_SUCCESS');
            action.payload.forEach(_id => {
                for (let i = 0; i < gallery.length; i++) {
                    if (gallery[i]._id === _id) {
                        gallery.splice(i, 1);
                    }
                }
            });
            //console.log('gallery after deletion ');
            //console.log(gallery);
            return {...state, gallery: {items: gallery, count: gallery.length}};
        }
        case types.GALLERY_ADD_SUCCESS: {
            hideDialog();
            let gallery = [...state.gallery.items];
            //console.log('GALLERY_ADD_SUCCESS');
            //console.log(gallery);
            if(gallery && Array.isArray(gallery))
                gallery.push(action.payload);
            else
                gallery = [action.payload];

            return {...state, gallery: {items: gallery, count: gallery.length}};
        }
        default:
            return state;
    }
}