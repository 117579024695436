import {getUser} from "./Auth";
import * as types from "./types";
import couponsApi from "../api/Coupons";
import {BrokenConnection, SessionExpired} from "../helpers";
import {openSnackbar} from "../components/commons/Notifier";

export const couponsFetch = (page, limit, sort, dir)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    couponsApi.get(user.token, page, limit, sort, dir).then(
                        result => {
                            dispatch({type: types.COUPONS_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const couponDelete = (id)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    couponsApi.delete(user.token,id).then(
                        () => {
                            dispatch({type: types.COUPON_DELETE_SUCCESS, payload: id});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Coupon deleted successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const couponAdd   = (code, expireDate, value)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    couponsApi.add(user.token, code, expireDate, value).then(
                        (result) => {
                            dispatch({type: types.COUPON_ADD_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`New Coupon '${code}' added successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const couponUpdate   = (_id, code, expireDate, value)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    couponsApi.update(user.token, _id, code, expireDate, value).then(
                        (result) => {
                            dispatch({type: types.COUPON_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Product '${code}' has been updated successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};