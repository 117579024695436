import axios from 'axios';
import Config from '../Config';

class AuthApi {
    constructor(){
        this.instance = axios.create({
            baseURL: Config.API_URL + 'auth',
            timeout: Config.TIME_OUT,
        });
    }
    login(user, password) {
        return this.instance({
            method: 'post',
            url: '/signInAdmin',
            data: {
                email:    user,
                password: password
            }
        });
    }
    validateToken(token){
        return this.instance({
            method: 'post',
            url: '/validateAdminToken',
            headers: {'x-auth-token': token}
        });
    }
}
export default new AuthApi();
