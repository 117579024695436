import config from "./config";
// import firebase from "firebase/firebase-app"
import firebase from 'firebase/app';
import "firebase/messaging"

export function initializeFirebase() {
    console.log('initializeFirebase');
    firebase.initializeApp(config);
    initializeServiceWorker();
}
function initializeServiceWorker(){
    console.log('initializeServiceWorker');
    navigator
        .serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
            firebase.messaging().useServiceWorker(registration);
        });
}
export const askForPermissioToReceiveNotifications = async () => {
    try {
        console.log('askForPermissio');
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();
        console.log('token do usuário:', token);
        return token;
    }
    catch (error) {
        console.error('Ask for perm Error: ' + error);
    }
};
// export const askForPermissioToReceiveNotifications = async () => {
//     try {
//         // const messaging = firebase.messaging();
//         // await messaging.requestPermission();
//         const resp = await Notification.requestPermission();
//         resp.then((res)=>{
//             console.log('Notification.requestPermission(): ' + res);
//         }).catch(err=>{
//             console.log('Notification.requestPermission(): ' + err);
//         });
//         return resp;
//         // return await messaging.getToken();
//     }
//     catch (error) {
//         console.error('Ask for perm Error: ' + error);
//     }
// };
export async function getToken() {
    const messaging = firebase.messaging();
    return await messaging.getToken();
}