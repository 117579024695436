import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {MuiPickersUtilsProvider, DateTimePicker} from '@material-ui/pickers';
import {connect} from 'react-redux';
import {TextField} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Casino from '@material-ui/icons/Casino';
import {
    Divider,
    Button,
    DialogContent,
    Typography,
    LinearProgress,
    InputAdornment,
    Input
} from "@material-ui/core";
import {couponAdd, couponUpdate} from '../../actions';
import GenRandString from '../../Utils/randomString';
import {hideDialog} from '../commons/Dialog'
import Moment from "@date-io/moment/build/index";

const styles = theme => ({
    container: {
        display: 'flex',
        width:'100%',
        flexGrow:1,
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:theme.spacing(1)
    },
    inline:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        flexGrow: 1,
        flexDirection: 'row'
    },
    spinner:{
        // marginLeft: theme.spacing.detail * 3,
        // marginLeft: theme.spacing.detail * 3,
        marginBottom: theme.spacing(4),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'46%'
    },
    textArea:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'95%'
    },
    oneThirdWidth:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'30%'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop:4,
        width:'46%',
        // flexGrow: 1
    },
    select:{
        //flexGrow: 1,
        //marginLeft: theme.spacing.detail,
        //marginRight: theme.spacing.detail
    }
});

class CouponForm extends Component {

    constructor(props){
        super(props);
        if(props.edit){
            console.log(props.item);
            this.state = {
                ...props.item
            };
        }else{
            this.state = {
                code:"",
                expireDate:null,
                value:0,
            };
        }

        this._handleDateTimeChange  = this._handleDateTimeChange.bind(this);
        this._handleChange  = this._handleChange.bind(this);
        this._genRandCode   = this._genRandCode.bind(this);
        this._save          = this._save.bind(this);
        this._update        = this._update.bind(this);
    }
    _handleChange = field => event =>{
        this.setState({[field]: event.target.value});
    };
    _handleDateTimeChange= field => value=>{
        this.setState({[field]: value});
    };
    _genRandCode(){
        this.setState({
            code: GenRandString('6')
        })
    }
    _save() {
        console.log(this.state);
        if(this.props.edit){
            this._update();
        }
        else {
            const {code, value, expireDate} = this.state;

            this.setState({
                err_code: code.trim().length < 5
            });
            this.setState({
                err_value: parseInt(value) === 0
            });
            this.setState({
                err_expireDate: expireDate === null
            });

            if (!(code.trim().length < 5 && value === 0 && expireDate === null ))
            {
                this.setState({loading: true});
                this.props.couponAdd(code, expireDate, parseInt(value));
            }
        }
    }
    _update(){
        const {code, value, expireDate} = this.state;
        this.setState({
            err_code: code.trim().length < 5
        });
        this.setState({
            err_value: parseInt(value) === 0
        });
        this.setState({
            err_expireDate: expireDate === null
        });

        if (!(code.trim().length < 5 && value === 0 && expireDate === null ))
        {
            this.setState({loading: true});
            this.props.couponUpdate(this.props.item._id, code, expireDate, parseInt(value));
        }
    }

    renderActionsButton(){
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._save} size="large" color="primary">
                        Save
                    </Button>
                    <Button onClick={hideDialog} size="large" color="secondary">
                        Dismiss
                    </Button>
                </div>
            );
        }
    }
    render() {
        const {classes} = this.props;
        const {code, value, expireDate, err_code, err_value, err_expireDate} = this.state;

        return (
            <DialogContent>
                <Typography gutterBottom variant="h5" component="h3">
                    New Product
                </Typography>
                <Divider/>
                <form className={classes.container} noValidate autoComplete="on">
                    <div className={classes.inline}>
                        <TextField
                            id="outlined-code"
                            label="Code"
                            error={err_code}
                            required
                            className={classes.textField}
                            value={code}
                            onChange={this._handleChange('code')}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton onClick={this._genRandCode} color="primary" className={classes.button} aria-label="randomize">
                                            <Casino />
                                        </IconButton>
                                    </InputAdornment>
                            }}
                        />
                        <TextField
                            id="value"
                            label="Coupon Value($)"
                            value={value}
                            onChange={this._handleChange('value')}
                            type="number"
                            className={classes.textField}
                            margin="normal"
                            variant="outlined"
                            error={err_value}
                            required
                        />
                    </div>
                    <MuiPickersUtilsProvider utils={Moment}>
                        <DateTimePicker
                            style={{paddingRight:10}}
                            label="Expire Date"
                            value={expireDate}
                            ampm={false}
                            allowKeyboardControl={false}
                            // helperText="Star"
                            onChange={this._handleDateTimeChange('expireDate')} />
                    </MuiPickersUtilsProvider>
                </form>
                {this.renderActionsButton()}
            </DialogContent>
        );
    }
}

const mapStateToProps = state => {
    const {loading} = state.general;
    return {loading};
};

export default connect(mapStateToProps, {couponAdd, couponUpdate})(withStyles(styles)(CouponForm));
