import axios from 'axios';
import Config from '../Config';

class GroupApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'group',
            timeout: Config.TIME_OUT,
        });
    }
    add(user, name, desc, file, type) {

        let data = new FormData();
        data.append('name', name);
        data.append('desc', desc);
        data.append('image', file);
        data.append('type', type);

        return this.instance({
            method: 'post',
            url: '/',
            headers: {'x-auth-token': user},
            data
        });
    }
    update(user,_id, name, desc, file, type) {

        let data = new FormData();
        data.append('name', name);
        data.append('desc', desc);
        data.append('type', type);

        if(file) {
            data.append('image', file);
        }

        return this.instance({
            method: 'put',
            url: `/${_id}`,
            headers: {'x-auth-token': user},
            data
        });
    }
    get(user) {
        return this.instance({
            method: 'get',
            url: '/',
            headers: {'x-auth-token': user}
        });
    }
    delete(user, id) {
        return this.instance({
            method: 'delete',
            url: `/${id}`,
            headers: {'x-auth-token': user}
        });
    }
    getProducts(user, id) {
        return this.instance({
            method: 'get',
            url: `/${id}/products`,
            headers: {'x-auth-token': user}
        });
    }

}
export default new GroupApi();