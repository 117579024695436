import {getUser} from "./Auth";
import * as types from "./types";
import customersApi from "../api/Customers";
import {BrokenConnection, SessionExpired} from '../helpers';

export const customersFetch = (page, limit, sort=null , dir = null)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('customersFetch');
                    customersApi.get(user.token, page, limit, sort, dir).then(
                        result => {
                            dispatch({type: types.CUSTOMERS_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch(err=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const customersSearch = (query, page, limit, sort=null, dir = null)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('customersFetch');
                    customersApi.search(user.token, query, page, limit, sort, dir).then(
                        result => {
                            dispatch({type: types.CUSTOMERS_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch(err=>{
                        BrokenConnection(err, dispatch);
                    });

                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};