import axios from 'axios';
import Config from '../Config';

class AuthApi {
    constructor(){
        this.instance = axios.create({
            baseURL: Config.API_URL + 'users',
            timeout: Config.TIME_OUT,
        });
    }
    updateToken(token, fcmToken) {
        return this.instance({
            method: 'put',
            url: '/',
            data:{
                fcmToken
            },
            headers: {
                'x-auth-token': token,
                'Content-Type':'application/json'
            }
        });
    }
}
export default new AuthApi();
