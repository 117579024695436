import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import MUIDataTable from "mui-datatables";
import {groupsFetch, groupDelete} from '../../actions';
import {hideDialog, showDialog} from '../commons/Dialog';
import {showAlertDialog} from '../commons/AlertDialog';
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import CustomToolbarSelect from "../commons/CustomToolbarSelect";
import GroupForm from "./GroupForm";
import {LinearProgress} from "@material-ui/core/es/index";
import {getFullUrl} from "../../Utils/image";
import {MuiTableWrapper} from "../commons/MuiTableWrapper";

const styles = theme => ({
    button: {
       // margin: theme.spacing.unit,
        color: '#56b75c'
    }
});

class GroupsList extends Component {

    constructor(props){
        super(props);
        this.props.groupsFetch();
    }
    prepareData(items){
        if(!items)
            return [];

        //console.log('items:');
        //console.log(items);
        return items.map((item)=>{
            return {...item, name: item.name, type: item.type};
        });
    }

    _onRowsDelete=(selectedRows)=>{
        // console.log(selectedRows);
        const {groupDelete, groups} = this.props;
        // this.setState({data: this.props.groups});
        let names = [];
        selectedRows.data.forEach((data)=>{
            names.push(groups[data.dataIndex].name);
        });

        showAlertDialog('Attention', `you are about to delete ${names.join(", ")}.`, ()=>{
            selectedRows.data.forEach((data)=>{
                groupDelete(groups[data.index]._id);
            });
        });
        return false;

    }
    _customToolbar=()=>{
        const { classes, user } = this.props;
        if(user.accessLevel.groups.write) {
            return (
                <IconButton onClick={this._addNewGroup} color="primary" className={classes.button}
                            aria-label="Add New Group">
                    <Add/>
                </IconButton>
            );
        }
        else {
            return null;
        }
    }
    _addNewGroup=()=>{
        showDialog(
            <GroupForm/>
        );
    }
    _onRowEdit=(selectedRows)=>{
        const {groups} = this.props;
        const item = groups[selectedRows.data[0].dataIndex];
        showDialog(
            <GroupForm item={item} edit/>
        );

    }

    renderLoading(loading){
        if(loading){
            return(
                <LinearProgress style={{color:'#4692e7'}}/>
            )
        }else{
            return null;
        }
    }
    render() {
        const {groups, user} = this.props;
        const columns = [
            {
                name:"name",
                label:"Name"
            },
            {
                name:"desc",
                label:"Description",
            },
            {
                name:"type",
                label:"Group Type"
            },
            {
                name:"thumbnail",
                label:"image",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const url = getFullUrl(value);
                        return (
                            <img src={url}
                                 height="100"
                                 width="100"
                                 style={{borderRadius:10000, objectFit:'cover'}}
                                 onClick={()=>{
                                     showDialog(
                                         <img src={url}
                                              width="100%"
                                              onClick={hideDialog}
                                              style={{ objectFit:'cover'}}/>

                                     );
                                 }}
                            />
                        );
                    }
                }
            }
        ];
        const options = {
            filter:false,
            responsive: "scroll",
            serverSide: false,
            fixedHeader:false,
            single:true,
            multiple:false,
            isRowSelectable: ()=>(user.accessLevel.groups.write),
            onRowsDelete: this._onRowsDelete,
            customToolbar: this._customToolbar,
            customToolbarSelect: selectedRows => (
                <CustomToolbarSelect selectedRows={selectedRows} onDelete={this._onRowsDelete} onEdit={this._onRowEdit}/>
            )
        };
        if(user.accessLevel.groups.read) {
            return (
                <MuiTableWrapper>
                    {this.renderLoading(this.props.loading)}
                    <MUIDataTable
                        title={"Groups"}
                        data={this.prepareData(groups)}
                        columns={columns}
                        options={options}
                    />
                </MuiTableWrapper>
            );
        }
        else {
            return <strong style={{fontSize:32}}>403 Access Denied</strong>
        }
    }
}

const mapStateToProps = state => {
    const {user} = state.auth;
    const {loading} = state.general;
    const {groups} = state.groups;
    return {groups, loading, user};
};

export default connect(mapStateToProps,{groupsFetch, groupDelete})(withStyles(styles)(GroupsList));
