import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/Add';
import MUIDataTable from "mui-datatables";
import {productFetch, productSearch, productDelete} from '../../actions';
import {showAlertDialog} from '../commons/AlertDialog';
import {hideDialog, showDialog} from "../commons/Dialog";
import ProductForm from "./ProductForm";
import CustomToolbarSelect from "../commons/CustomToolbarSelect";
import {LinearProgress} from "@material-ui/core/es/index";
import {getFullUrl} from "../../Utils/image";
import {MuiTableWrapper} from "../commons/MuiTableWrapper";
import ProductDetails from "./ProductDetails";
const styles = theme => ({
    button: {
       // margin: theme.spacing.unit,
        color: '#56b75c'
    }
});

class ProductsList extends Component {

    constructor(props){
        super(props);
        this.props.productFetch(1,10);
        this._onTableChange = this._onTableChange.bind(this);
        this._onRowsDelete  = this._onRowsDelete.bind(this);
        this._customToolbar = this._customToolbar.bind(this);
        this._addNewProduct = this._addNewProduct.bind(this);
        this._onRowEdit     = this._onRowEdit.bind(this);
        this.renderExpandableRow     = this.renderExpandableRow.bind(this);
    }

    prepareData(items){
        if(!items)
            return [];

        //console.log('product list prepare data');
        return items.map((item)=>{
            let group = "Undefined!";
            if(item.group) {
                group = item.group.name;
            }

            let prices = "";
            if(item.prices.adult)
                prices += item.prices.adult + "/";
            if(item.prices.senior)
                prices += item.prices.senior + "/";
            if(item.prices.child)
                prices += item.prices.child + "/";
            if(item.prices.infant)
                prices += item.prices.infant + "/";

            prices = prices.substr(0, prices.length - 1) + "$";
            return {...item, price: prices, group};
        });
    }
    _onTableChange(action, tableState){
        //console.log('_onTableChange');
        //console.log(action);
        if(action === 'rowsSelect' || action === 'expandRow') return;
        if(action === 'sort' || action !== 'propsUpdate' || action === 'search') {
            //console.log(tableState);
            this.tableState = tableState;
            const {page, rowsPerPage, searchText, activeColumn, columns} = tableState;
            let sort = null;
            let sortDir = null;
            //console.log(activeColumn);

            if (activeColumn !== null) {
                sort = columns[activeColumn].name;
                sortDir = columns[activeColumn].sortDirection;
                switch (sort) {
                    case "name":
                        sort = "name";
                        break;
                    case "detail":
                        sort = "detail";
                        break
                }
            }
            //console.log('rowsPerPage: ' + rowsPerPage);
            if (searchText) {
                this.props.productSearch(searchText, page + 1, rowsPerPage, sort, sortDir);
            }
            else {
                this.props.productFetch(page + 1, rowsPerPage, sort, sortDir);
            }
        }
    }
    _onRowsDelete(rowsDeleted){
        const {productDelete, products} = this.props;
        this.setState({data: this.prepareData(products.items)});
        let names = [];
        rowsDeleted.data.forEach((data)=>{
            names.push(products.items[data.index].name);
        });

        showAlertDialog('Attention', `you are about to delete ${names.join(", ")}.`, ()=>{
            rowsDeleted.data.forEach((data)=>{
                productDelete(products.items[data.index]._id);
            });
        });
        return false;

    }
    _customToolbar(){
        const { classes, user } = this.props;
        if(user.accessLevel.products.write) {
            return (
                <IconButton onClick={this._addNewProduct} color="primary" className={classes.button}
                            aria-label="Add New Product">
                    <Add/>
                </IconButton>
            );
        }
        else {
            return null;
        }

    }
    _addNewProduct(){
        showDialog(
            <ProductForm/>
        );
    }
    _onRowEdit(selectedRows){
        const {products} = this.props;
        const item = products.items[selectedRows.data[0].dataIndex];
        showDialog(
            <ProductForm item={item} edit/>
        );
    }
    renderExpandableRow(rowData, rowMeta){
        const {items} = this.props.products;
        const item = items[rowMeta.dataIndex];
        return(<ProductDetails item={item} />)
    }

    renderLoading(loading){
        if(loading){
            return(
                <LinearProgress style={{color:'#4692e7'}}/>
            )
        }else{
            return(<view></view>);
        }
    }
    render() {
        const {user} = this.props;
        const {items, count} = this.props.products;
        const columns = [
            {
                name:"name",
                label:"Name"
            },
            {
                name:"group",
                label:"Group"
            },
            {
                name:"price",
                label:"Price"
            },
            {
                name:"duration",
                label:"Duration"
            },
            {
                name:"qty",
                label:"Quantity"
            },
            // {
            //     name:"detail",
            //     label:"Itinerary"
            // },
            // {
            //     name:"highlights",
            //     label:"Highlights"
            // },
            {
                name:"thumbnail",
                label:"image",
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        const url = getFullUrl(value);
                        return (
                            <img src={url}
                                 height="100"
                                 width="100"
                                 style={{borderRadius:10000, objectFit:'cover'}}
                                 onClick={()=>{
                                     showDialog(
                                         <img src={url}
                                              width="100%"
                                              onClick={()=>{hideDialog();}}
                                              style={{ objectFit:'cover'}}/>

                                     );
                                 }}
                            />
                        );
                    }
                }
            }

        ];
        const options = {
            // filterType: "dropdown",
            filter:false,
            responsive: "scroll",
            serverSide: true,
            fixedHeader:false,
            count: count,
            onRowsDelete: this._onRowsDelete,
            onTableChange: this._onTableChange,
            customToolbar: this._customToolbar,
            expandableRows:true,
            isRowSelectable: ()=>(user.accessLevel.products.write),
            renderExpandableRow: this.renderExpandableRow,
            customToolbarSelect: selectedRows => (
                <CustomToolbarSelect selectedRows={selectedRows} onDelete={this._onRowsDelete} onEdit={this._onRowEdit}/>
            )

        };
        if(user.accessLevel.products.read) {
            return (
                <MuiTableWrapper>
                    {this.renderLoading(this.props.loading)}
                    <MUIDataTable
                        title={"Activities"}
                        data={this.prepareData(items)}
                        columns={columns}
                        options={options}/>
                </MuiTableWrapper>
            );
        }
        else {
            return <strong style={{fontSize:32}}>403 Access Denied</strong>
        }
    }
}

const mapStateToProps = state => {
    const {user} = state.auth;
    const {products} = state.products;
    const {loading} = state.general;
    return {products, loading, user};
};

export default connect(mapStateToProps,{productFetch, productSearch, productDelete})(withStyles(styles)(ProductsList));