import {getUser} from "./Auth";
import * as types from "./types";
import servicesApi from "../api/Services";
import {BrokenConnection, SessionExpired} from "../helpers";
import {openSnackbar} from "../components/commons/Notifier";

export const serviceFetch = (page, limit, sort, dir)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('here');
                    servicesApi.get(user.token, page, limit, sort, dir).then(
                        result => {
                            dispatch({type: types.SERVICES_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const serviceDelete = (id)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    servicesApi.delete(user.token,id).then(
                        () => {
                            dispatch({type: types.SERVICES_DELETE_SUCCESS, payload: id});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Product deleted successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const serviceAdd   = (title, url, order, status, image)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    servicesApi.add(user.token, title, url, order, status, image).then(
                        (result) => {
                            dispatch({type: types.SERVICES_ADD_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`New Service '${title}' added successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};

export const serviceUpdate   = (_id, title, url, order, status, image)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    servicesApi.update(user.token, _id, title, url, order, status, image).then(
                        (result) => {
                            dispatch({type: types.SERVICES_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Service '${title}' has been updated successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const serviceUpdateStatus   = (_id, status)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    servicesApi.updateStatus(user.token, _id, status).then(
                        (result) => {
                            dispatch({type: types.SERVICES_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Service has been updated successfully`, 2000);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                   openSnackbar(`Session has been expired`, 3000);
                   dispatch({type: types.LOGOUT_USER});
                });
        }
    );
};