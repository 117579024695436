import {openSnackbar} from "./components/commons/Notifier";
import * as types from "./actions/types";

export function BrokenConnection(err, dispatch) {
    console.log('BrokenConnection');
    console.log(err);
    dispatch({type: types.STOP_LOADING});
    if(err === null )
        return;
    else if(!err.response){
        openSnackbar(err);
        return;
    }

    console.log(err);

    const {data, status} = err.response;
    if(data === "")
        openSnackbar("Broken Connection");
    else if (status === 401) {
        dispatch({type: types.LOGOUT_USER});
        openSnackbar("Token has been expired!");
    }
    else if (status === 404)
        openSnackbar("Not Found!");
    else if (status === 400 && data !== "")
        openSnackbar(data);
    else if (status === 400 && data === "")
        openSnackbar("Bad Request");
    else
        openSnackbar(data);
}
export function SessionExpired(dispatch) {
    openSnackbar(`Session has been expired`, 3000);
    dispatch({type: types.LOGOUT_USER});
}