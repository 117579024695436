import * as types from './types';
import authApi from '../api/Auth';
import userApi from '../api/User';
import {openSnackbar} from '../components/commons/Notifier';
import {getToken} from '../Utils/fcm';
import {SessionExpired} from '../helpers';

export const loginUser      = (info)=>{
    const {email, password} = info;
    return(dispatch)=>{
        dispatch({type:types.START_LOADING});
        authApi.login(email, password)
        .then((result)=>{
            loginUserSuccess(dispatch, result.data);
        })
        .catch((err)=>{
            console.log('err', err);
            dispatch({type:types.STOP_LOADING});
            if(err.response){
                const {status, data} = err.response;
                loginUserFailed(dispatch, status, data);
            }else {
                FatalError();
            }
        })
    }
};
export const getUser        = ()=>{
    return((dispatch, getState) => {
        return new Promise((resolve, reject)=>{
            const user = getState().auth.user;
            //console.log('getUser');
            //console.log(user);
            if(!user){
                //console.log('no user found');
                reject();
            }else {
                //console.log('user found');
                resolve(user);
            }
        });
    });
};
export const autoSignIn     = ()=>{
    //console.log('auto sign in');
    return((dispatch) => {
            dispatch(getUser())
                .then((user)=>{
                    //console.log('sign in');
                    loginUserSuccess(dispatch, user);
                })
                .catch((err)=>{
                    console.log(err);
                });
        }
    );
};
export const validateToken  = ()=>  {
    return((dispatch, getState) => {
        const user = getState().auth.user;
        if(!user){
            // return new Promise((resolve, reject) => {
            //     reject();
            // });
            //console.log('token does not exist');
            // SessionExpired();
        }
        else {
            authApi.validateToken(user.token)
            .catch((err)=>{
                console.log('token is invalid', err);
                SessionExpired(dispatch);
            })
        }
    });
};
export const logOut  = ()=>{
    return((dispatch) => {
        dispatch({type:types.LOGOUT_USER});
    });
};
export const updateFirebaseToken  = ()=>{
    return((dispatch) => {
            dispatch(getUser())
                .then((user)=>{
                    updateFCMToken(dispatch, user);
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
    });
};

const loginUserFailed = (dispatch, status, message)=>{
    if(status === 404){
        openSnackbar('Wrong Email or Password!');
    }
    else {
        openSnackbar(message);
    }
};
const loginUserSuccess = (dispatch, user)=>{
    updateFCMToken(dispatch, user);
    dispatch({type:types.STOP_LOADING});
    dispatch({
        type: types.LOGIN_USER_SUCCESS,
        payload: user
    });
    openSnackbar(`Welcome ${user.name}`);
};
function updateFCMToken (dispatch, user){
    console.log('updateFCMToken');
    getToken().then(fcmToken=>{
        console.log('fcmToken: ', fcmToken);
        if(!user.fcmToken || user.fcmToken !== fcmToken) {
            userApi.updateToken(user.token, fcmToken).then(
                (result) => {
                    console.log('fcm token updated successfully');
                    console.log(result);
                    dispatch({type:types.UPDATE_USER, key:'fcmToken', payload:result.data.fcmToken});
                }
            );
        }
        else {
            //console.log('Token is already update')
        }
    });
};
const FatalError = ()=>{
    openSnackbar("Can't reach server, please check internet connection!");
}