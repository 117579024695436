import {getUser} from "./Auth";
import * as types from "./types";
import productsApi from "../api/Products";
import {BrokenConnection, SessionExpired} from "../helpers";
import {openSnackbar} from "../components/commons/Notifier";

export const productFetch = (page, limit, sort, dir)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('here');
                    productsApi.get(user.token, page, limit, sort, dir).then(
                        result => {
                            dispatch({type: types.PRODUCTS_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const productSearch = (query, page, limit, sort, dir)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('here');
                    productsApi.search(user.token,query, page, limit, sort, dir).then(
                        result => {
                            dispatch({type: types.PRODUCTS_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const productDelete = (id)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    //console.log('here');
                    productsApi.delete(user.token,id).then(
                        () => {
                            dispatch({type: types.PRODUCTS_DELETE_SUCCESS, payload: id});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Product deleted successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
// name, price, duration, qty, group, detail, summery, highlights, files[0]
export const productAdd   = (name, prices, duration, /*qty,*/ group, detail, summery, highlights, image)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    productsApi.add(user.token, name, prices, duration, /*qty,*/ group, detail, summery, highlights, image).then(
                        (result) => {
                            //console.log('product add: ' + result.data);
                            dispatch({type: types.PRODUCTS_ADD_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`New Product '${name}' added successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
//name, price, duration, qty, group, detail, summery, highlights, files[0]
export const productUpdate   = (_id, name, prices, duration, /*qty,*/ group, detail, summery, highlights, image)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    productsApi.update(user.token, _id, name, prices, duration, /*qty,*/ group, detail, summery, highlights, image).then(
                        (result) => {
                            dispatch({type: types.PRODUCTS_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Product '${name}' has been updated successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const productUpdateStatus   = (_id, status)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    productsApi.updateStatus(user.token, _id, status).then(
                        (result) => {
                            dispatch({type: types.PRODUCTS_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.GROUP_PRODUCTS_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Product has been updated successfully`, 2000);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                   openSnackbar(`Session has been expired`, 3000);
                   dispatch({type: types.LOGOUT_USER});
                });
        }
    );
};