import axios from 'axios';
import Config from '../Config';
import {openSnackbar} from "../components/commons/Notifier";

class NotificationApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'notifications',
            timeout: Config.TIME_OUT,
        });
    }
    sendAll(user, title, body, file) {

        let data = new FormData();
        data.append('title', title);
        data.append('body', body);
        if(file)
            data.append('image', file);

        let ref = this.instance({
            method: 'post',
            url: '/',
            headers: {'x-auth-token': user},
            data,
        });
        ref.then((result) => {
            //console.log(result.data);
            let recievedNum = 0;
            result.data.forEach(item=>{
                if(item.success) recievedNum++;
            });
            openSnackbar(`Message sent successfully to ${recievedNum} users`)
        })
        .catch((err) => {
            console.log(err);
        });
        return ref;
    }
    sendAdmins(user, title, body, file) {

        let data = new FormData();
        data.append('title', title);
        data.append('body', body);
        if(file)
            data.append('image', file);

        let ref = this.instance({
            method: 'post',
            url: '/sendToAdmins',
            headers: {'x-auth-token': user},
            data,
        });
        ref.then((result) => {
            //console.log(result.data);
            let recievedNum = 0;
            result.data.forEach(item=>{
                if(item.success) recievedNum++;
            });
            openSnackbar(`Message sent successfully to ${recievedNum} users`)
        })
        .catch((err) => {
            console.log(err);
        });
        return ref;
    }

}
export default new NotificationApi();