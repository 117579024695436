import axios from 'axios';
import Config from '../Config';

class ProductApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'services',
            timeout: Config.TIME_OUT,
        });
    }

    get(user, page, limit, sort=null, dir=null) {
        let url = `?page=${page}&limit=${limit}`;
        if(sort){
            url += `&sort=${sort}:${dir}`
        }
        return this.instance({
            method: 'get',
            url,
            headers: {'x-auth-token': user}
        });
    }
    add(user, title, url, order, status, image) {
        let data = new FormData();
        data.append('title', title);
        data.append('url', url);
        data.append('order', order);
        data.append('image', image);
        data.append('status', status);

        return this.instance({
            method: 'post',
            url:'/',
            headers: {'x-auth-token': user},
            data
        });
    }
    delete(user, id) {
        return this.instance({
            method: 'delete',
            url: `/${id}`,
            headers: {'x-auth-token': user}
        });
    }
    update(user, _id, title, url, order, status, image) {
        let data = new FormData();
        if(title)
            data.append('title', title);
        if(url)
            data.append('url', url);
        if(order)
            data.append('order', order);
        if(image)
            data.append('image', image);
        if(status)
            data.append('status', status);

        return this.instance({
            method: 'put',
            url:`/${_id}`,
            headers: {'x-auth-token': user},
            data
        });
    }
    updateStatus(user, _id, status) {
        let data = new FormData();
        if(status)
            data.append('status', status);

        return this.instance({
            method: 'put',
            url:`/${_id}`,
            headers: {'x-auth-token': user},
            data
        });
    }

}
export default new ProductApi();