import * as types from '../actions/types';
import {hideDialog} from "../components/commons/Dialog";
import {openSnackbar} from "../components/commons/Notifier";
const INITIAL_STATE = {
    products:[]
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.PRODUCTS_FETCH_SUCCESS:
            return {...state, products: action.payload};
        case types.PRODUCTS_DELETE_SUCCESS:
            let products = [];
            //console.log(state.products);
            state.products.items.forEach(item=>{
                if(item._id !== action.payload){
                    products.push(item);
                }
            });
            return {...state, products: {items: products, count: products.length}};
        case types.PRODUCTS_UPDATE_SUCCESS: {
            hideDialog();
            let products = [...state.products.items];
            //console.log(state.products);
            for(let i=0 ; i < products.length ; i++){
                if (products[i]._id === action.payload._id) {
                    products[i] = action.payload;
                }
            }
            return {...state, products: {items: products, count: products.length}};

        }
        case types.PRODUCTS_ADD_SUCCESS:
            hideDialog();

            //console.log(action.payload);
            const {items, count} = state.products;
            //console.log('here');
            let newState = {...state, products:{ items: [...items, action.payload], count: count+1 }, loading: false, error: ""};
            //console.log(newState);
            return newState;
        case types.PRODUCTS_ADD_FAILED:
            openSnackbar(action.payload);
            return {...state, loading: false, error: action.payload};

        default:
            return state;
    }
}