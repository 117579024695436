import * as types from '../actions/types';

const INITIAL_STATE = {
    loading: false
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.START_LOADING:
            return {...state, loading:true};
        case types.STOP_LOADING:
            return {...state, loading:false};

        default:
            return state;
    }
}