import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Route } from "react-router-dom";
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {CssBaseline, Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { MainDrawerItems } from './DrawerItems';
import Dashboard from '../Dashboard/Dashboard';
import {logOut, updateFirebaseToken} from '../../actions/index';
import CustomerList from '../Customers/CustomersList';
import ProductsList from '../Products/ProductsList';
import OrdersList from '../Orders/OrdersList';
import ServicesList from "../Services/ServicesList";
import Order from '../Orders/Order';
import GroupsList from '../Groups/GroupsList';
import CouponList from '../Coupons/CouponList';
import Notification from "../Notifications/Notification";

import {showDialog} from '../commons/Dialog';
import {isSmallWindow} from '../../App';
const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        backgroundColor:'#4692e7',
        paddingRight: 24,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9)
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        backgroundImage: `url(${require('../../images/background.jpg')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width:'100%',
        flexGrow: 1,
        marginTop: 64,
        padding: isSmallWindow()?0:theme.spacing(2),
        height: '100vh',
        overflow: 'auto',
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 320,
    },
    h5: {
        marginBottom: theme.spacing(2),
    }
});

class Base extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.props.updateFirebaseToken();
    }
    handleDrawerOpen = () => {
        this.setState({ open: true });
    };
    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { classes, newOrders, logOut, user} = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="absolute"
                    // position="relative"
                    className={classNames(classes.appBar, this.state.open && classes.appBarShift)}>
                    <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(
                                classes.menuButton,
                                this.state.open && classes.menuButtonHidden,
                            )}>
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            className={classes.title}
                        >
                            Arian Travel Dashboard
                        </Typography>
                        <IconButton color="inherit" onClick={()=>{showDialog(<Notification/>)}}>
                            {/*<Badge badgeContent={4} color="secondary">*/}
                                <NotificationsIcon />
                            {/*</Badge>*/}
                        </IconButton>
                        <IconButton color="inherit" onClick={logOut}>
                            <ExitToApp />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                    }}
                    open={this.state.open}>
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{MainDrawerItems(newOrders, user.accessLevel)}</List>
                </Drawer>
                <main className={classes.content}>
                    <Route path="/" exact component={Dashboard}/>
                    <Route path="/Customers" exact component={CustomerList}/>
                    <Route path="/Orders" exact component={OrdersList}/>
                    <Route path="/Order/:id" component={Order}/>
                    <Route path="/Groups" exact component={GroupsList}/>
                    <Route path="/Products" exact component={ProductsList}/>
                    <Route path="/Services" exact component={ServicesList}/>
                    <Route path="/coupons" exact component={CouponList}/>
                </main>
            </div>
        );
    }
}

Base.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const {user} = state.auth;
    const {newOrders} = state.orders;
    return {newOrders, user};
};

export default connect(mapStateToProps, {logOut, updateFirebaseToken})(withStyles(styles)(Base));
