import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core/es/index";

const useStyles = makeStyles(theme => ({
    root: {
        width: '200%',
        marginTop: theme.spacing(3),
        margin:25,
        overflowX: 'auto',
        // alignItems:'center'
    },
    table: {
        //minWidth: 650,
    },
    title: {
        flex: '0 0 auto',
        marginLeft:16,
        fontWeight:'bold',
        marginTop:16
    },
}));

function getItemPrice(item) {
    let totalPrice = 0;
    if(item.prices && item.passengersObj) {
        const passengersObj = item.passengersObj;
        totalPrice += passengersObj.adult  && item.prices.adult  ? item.prices.adult  * passengersObj.adult  : 0;
        totalPrice += passengersObj.senior && item.prices.senior ? item.prices.senior * passengersObj.senior : 0;
        totalPrice += passengersObj.child  && item.prices.child  ? item.prices.child  * passengersObj.child  : 0;
        totalPrice += passengersObj.infant && item.prices.infant ? item.prices.infant * passengersObj.infant : 0;

    }
    return totalPrice;
}

export default function OrderDetails({items}) {
    const classes = useStyles();
    let totalPrice = 0;
    items.forEach(item=>{
        totalPrice += getItemPrice(item);
    });
    return (
        <Paper className={classes.root}>
            <div className={classes.title}>
                <Typography color="inherit" variant="subtitle1">
                    Order Items
                </Typography>
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Product Name</TableCell>
                        {/*<TableCell align="right">Quantity</TableCell>*/}
                        <TableCell align="left">Passengers</TableCell>
                        <TableCell align="left">Price</TableCell>
                        {/*<TableCell align="right"></TableCell>*/}
                        {/*<TableCell align="right">Protein&nbsp;(g)</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map(item => (
                        <TableRow key={item._id}>
                            <TableCell component="th" scope="row">
                                {item.productId.name}
                            </TableCell>
                            {/*<TableCell align="right">{item.qty}</TableCell>*/}
                            <TableCell align="left" style={{flexDirection:'column', alignItems:'flex-end'}}>
                            {
                                item.passengersObj.adult > 0
                                    ?<p style={{margin:0}}>Adult: {item.passengersObj.adult}</p>
                                    :null
                            }
                            {
                                item.passengersObj.senior > 0
                                    ?<p style={{margin:0}}>Senior: {item.passengersObj.senior}</p>
                                    :null
                            }
                            {
                                item.passengersObj.child > 0
                                    ?<p style={{margin:0}}>Child: {item.passengersObj.child}</p>
                                    :null
                            }
                            {
                                item.passengersObj.infant > 0
                                    ?<p style={{margin:0}}>Infant: {item.passengersObj.infant}</p>
                                    :null
                            }
                            </TableCell>
                            <TableCell align="left">${getItemPrice(item)}</TableCell>
                            {/*<TableCell align="right">{item.carbs}</TableCell>*/}
                            {/*<TableCell align="right">{item.protein}</TableCell>*/}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell align="center">Total: ${totalPrice}</TableCell>
                        {/*<TableCell align="left">${totalPrice}</TableCell>*/}
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}