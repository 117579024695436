import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {getDimension} from '../../App';
let showDialog, hideDialog;

class _Dialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
            style:{},
            fullScreen:false
        };
        this._onAgree = this._onAgree.bind(this);
        this._onDisagree = this._onDisagree.bind(this);
    }
    componentDidMount() {
        showDialog = this.showDialog;
        hideDialog = this.closeDialog;
    }

    showDialog = (content, maxWidth, fullScreen) => {
        if(getDimension().width < 640) fullScreen = true;
        this.setState({
            open: true,
            content,
            fullScreen,
            maxWidth
        })
    };
    closeDialog = () => {
        this.setState({ open: false });
    };

    _onDisagree(){
        this.state.onDisagree();
        this.closeDialog();
    }
    _onAgree(){
        this.state.onAgree();
        this.closeDialog();
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                scroll={"paper"}
                style={this.state.style}
                disableEnforceFocus
                maxWidth={this.state.maxWidth}
                fullScreen={this.state.fullScreen}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/*<DialogContent>*/}
                    {this.state.content}
                {/*</DialogContent>*/}
            </Dialog>
        );
    }
}

export {showDialog, hideDialog};
export default _Dialog;