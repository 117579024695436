import ReactDOM from "react-dom";
import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField} from "@material-ui/core";
import {
    Divider,
    Button,
    DialogContent,
    FormControl,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
    Typography,
    LinearProgress,
} from "@material-ui/core";
import {serviceAdd, serviceUpdate} from '../../actions';
import DropZone from '../commons/DropZone';
import {hideDialog} from '../commons/Dialog'

const styles = theme => ({
    container: {
        display: 'flex',
        width:'100%',
        flexGrow:1,
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:theme.spacing(1)
    },
    inline:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        flexGrow: 1,
        flexDirection: 'row'
    },
    spinner:{
        // marginLeft: theme.spacing.detail * 3,
        // marginLeft: theme.spacing.detail * 3,
        marginBottom: theme.spacing(4),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'46%'
    },
    textField25: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'23%'
    },
    textArea:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'95%'
    },
    oneThirdWidth:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'30%'
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        marginTop:4,
        width:'46%',
        // flexGrow: 1
    },
    select:{
        //flexGrow: 1,
        //marginLeft: theme.spacing.detail,
        //marginRight: theme.spacing.detail
    }
});

class ServiceForm extends Component {

    constructor(props){
        super(props);
        if(props.edit){
            console.log(props.item);
            this.state = {
                ...props.item,
                files: [],
                labelWidth: 0
            };
        }else{
            this.state = {
                title:"",
                url:"",
                status:"active",
                order:0,
                files: [],
                labelWidth: 0,
            };
        }

        this._handleChange  = this._handleChange.bind(this);
        this._handlePassengersPriceChange  = this._handlePassengersPriceChange.bind(this);
        this._handleSummeryChange = this._handleSummeryChange.bind(this);
        this._onUpdateFiles = this._onUpdateFiles.bind(this);
        this._save          = this._save.bind(this);
    }
    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }
    _handleChange = field => event =>{
        this.setState({[field]: event.target.value});
    };
    _handleSummeryChange = field => event =>{
        this.setState({
            summery:{
                ...this.state.summery,
                [field]: !this.state.summery[field]
            }
        })
    }
    _handlePassengersPriceChange = field => event=>{
        // try {
            this.setState({
                prices: {
                    ...this.state.prices,
                    [field]: event.target.value === "" ? null : parseInt(event.target.value)
                }
            })
        // }catch (e) {}
    };
    _onUpdateFiles(files) {
        //console.log(files);
        this.setState({
            files: files
        });
    }
    _save() {
        console.log(this.state);
        const {title, url, status, order, files, err_title, err_url, err_status, err_order, err_files} = this.state;

        this.setState({
            err_name: title.trim().length < 3
        });
        const urlPattern = /^(http|https):\/\/[^ "]+$/;
        this.setState({
            err_url: !urlPattern.test(url)
        });

        if(this.props.edit) {
            if (title.trim().length > 2 && urlPattern.test(url)) {
                this.props.serviceUpdate(this.props.item._id, title, url, order, status, files[0]);
            }
        }
        else {
            this.setState({err_files: files.length === 0});
            if (title.trim().length > 2 && urlPattern.test(url) && files.length >= 1){
                this.props.serviceAdd(title, url, order, status, files[0]);
            }
        }

    }

    renderActionsButton(){
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._save} size="large" color="primary">
                        Save
                    </Button>
                    <Button onClick={hideDialog} size="large" color="secondary">
                        Dismiss
                    </Button>
                </div>
            );
        }
    }
    render() {
        const {classes} = this.props;
        const {title, url, status, order, err_title, err_url, err_status, err_order, err_files} = this.state;
        return (
            <DialogContent>
                <Typography gutterBottom variant="h5" component="h3">
                    New Service
                </Typography>
                <Divider/>
                <form className={classes.container} noValidate autoComplete="on">
                    <div className={classes.inline}>
                        <TextField
                            label="Title"
                            error={err_title}
                            type="string"
                            required
                            className={classes.oneThirdWidth}
                            value={title}
                            onChange={this._handleChange('title')}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="order"
                            className={classes.oneThirdWidth}
                            value={order}
                            onChange={this._handleChange('order')}
                            type="number"
                            margin="normal"
                            variant="outlined"
                            error={err_order}
                            required
                        />
                        <FormControl variant="outlined"
                                     className={classes.oneThirdWidth}
                                     error={err_status}
                                     required>
                            <InputLabel
                                ref={ref => {this.InputLabelRef = ref;}}
                                htmlFor="outlined-status-simple">
                                Status
                            </InputLabel>
                            <Select
                                value={status}
                                className={classes.select}
                                onChange={this._handleChange('status')}
                                input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                        name="status"
                                        id="outlined-status-simple"
                                    />
                                }>
                                <MenuItem value={'active'} key={'active'}>Active</MenuItem>
                                <MenuItem value={'inactive'} key={'inactive'}>InActive</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <TextField
                        label="Url"
                        value={url}
                        onChange={this._handleChange('url')}
                        type="string"
                        style={{width:'95%'}}
                        margin="normal"
                        variant="outlined"
                        error={err_url}
                        required/>

                </form>
                <DropZone
                    style={{width:'95%'}}
                    error={err_files}
                    onupdatefiles={this._onUpdateFiles}/>
                {this.renderActionsButton()}
            </DialogContent>
        );
    }
}

const mapStateToProps = state => {

    const {groups} = state.groups;
    const {loading} = state.general;
    return {loading,groups};
};

export default connect(mapStateToProps, {serviceAdd, serviceUpdate})(withStyles(styles)(ServiceForm));
