import * as types from '../actions/types';
import {hideDialog} from "../components/commons/Dialog";
import {openSnackbar} from "../components/commons/Notifier";
const INITIAL_STATE = {
    services:[]
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.SERVICES_FETCH_SUCCESS:
            return {...state, services: action.payload};
        case types.SERVICES_DELETE_SUCCESS:
            let services = [];
            //console.log(state.products);
            state.services.items.forEach(item=>{
                if(item._id !== action.payload){
                    services.push(item);
                }
            });
            return {...state, products: {items: services, count: services.length}};
        case types.SERVICES_UPDATE_SUCCESS: {
            hideDialog();
            let services = [...state.services.items];
            for(let i=0 ; i < services.length ; i++){
                if (services[i]._id === action.payload._id) {
                    services[i] = action.payload;
                }
            }
            return {...state, services: {items: services, count: services.length}};

        }
        case types.SERVICES_ADD_SUCCESS:
            hideDialog();
            const {items, count} = state.services;
            return {...state, services:{ items: [...items, action.payload], count: count+1 }, loading: false, error: ""};

        default:
            return state;
    }
}