Date.prototype.addDays = function(days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

exports.getPreviousMonday = function() {
    let date = new Date();
    let day = date.getDay();
    let prevMonday;
    if(date.getDay() === 1){
        prevMonday = date.addDays(-7);
    }
    else{
        prevMonday = date.addDays(day);
    }
    console.log('prevMonday', prevMonday);
    return new Date(prevMonday);
};

