/*
* this page should display order informations including items qty prices
* user information including delivery method, address billing type/info
* user should can edit/process/cancel or refund order(optional)
* */
import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {ordersGetById} from '../../actions';
import {Card, CardContent, Typography} from "@material-ui/core";

const styles = theme => ({
    button: {
        // margin: theme.spacing.unit,
        color: '#56b75c'
    }
});

class Order extends Component {

    constructor(props){
        super(props);
        const {ordersGetById, match} = this.props;
        ordersGetById(match.params.id);
    }

    render() {
        const {order} = this.props;
        //console.log(order);
        return (
            <div>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Customer
                        </Typography>
                        <Typography component="p">
                            {/*{order.user.name}*/}
                            {/*{order.address.details}*/}
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            Address
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {

    const {order} = state.orders;
    return {order};
};

export default connect(mapStateToProps,{ordersGetById})(withStyles(styles)(Order));
