import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {TextField} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import DropZone from '../commons/DropZone';
import {hideDialog} from '../commons/Dialog'
import {Button, Typography,
    FormControl,
    InputLabel, MenuItem,
    OutlinedInput,
    Select,
} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import {groupAdd, groupEdit} from '../../actions';
import CardActions from '@material-ui/core/CardActions';
import ReactDOM from "react-dom";
import {DialogContent} from "@material-ui/core/es/index";

const styles = theme => ({
    container: {
        display: 'flex',
        width:'100%',
        flexGrow:1,
        flexWrap: 'wrap',
        justifyContent:'center',
        margin:theme.spacing(1)
    },
    inline:{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:'center',
        flexGrow: 1,
        flexDirection: 'row'
    },
    spinner:{
        // marginLeft: theme.spacing.detail * 3,
        // marginLeft: theme.spacing.detail * 3,
        marginBottom: theme.spacing(4),
    },
    textField: {
        // marginLeft: theme.spacing(1),
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'45%'
    },
    textArea:{
        marginTop:4,
        marginRight: theme.spacing(1),
        width:'96%'
    },
    formControl: {
        // margin: theme.spacing(1),
        marginTop:4,
        width:'50%',
        // flexGrow: 1
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    select:{
        //flexGrow: 1,
        //marginLeft: theme.spacing.detail,
        //marginRight: theme.spacing.detail
    }
});

class GroupForm extends Component {

    constructor(props){
        super(props);
        if(props.edit) {
            const {item} = props;
            this.state = {
                ...item,
                files: []
            };
        }
        else {
            this.state = {
                name: "",
                desc:"",
                type: '',
                files: []
            };
        }
        this._handleChange  = this._handleChange.bind(this);
        this._onUpdateFiles = this._onUpdateFiles.bind(this);
        this._save          = this._save.bind(this);
        this._update          = this._update.bind(this);
        this.setError       = this.setError.bind(this);
    }
    componentDidMount() {
        this.setState({
            labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
        });
    }
    _handleChange = field => event =>{
        this.setState({[field]: event.target.value});
    };
    _onUpdateFiles(files) {
        this.setState({files: files});
    }
    setError(error){
        this.setState((state,props)=>({error : {...state.error, error}}));
    }
    _save(){
        if(this.props.edit){
            this._update();
        }
        else {
            const {files, name, type, desc} = this.state;
            this.setState({
                ...this.state,
                err_name: name.trim().length < 2
            });
            this.setState({
                ...this.state,
                err_files: files.length === 0
            });
            this.setState({
                ...this.state,
                err_type: type !== 'timed' && type !== 'numbered'
            });

            if (files.length === 1 && name.trim().length > 2 && (type === 'timed' || type === 'numbered')) {
                this.props.groupAdd(name, desc, files[0], type);
            }
            //console.log(this.state);
        }
    }
    _update(){
        //console.log('edit');
        const {files, name, type, desc} = this.state;
        const {item} = this.props;
        this.setState({
            ...this.state,
            err_name: name.trim().length < 2
        });
        this.setState({
            ...this.state,
            err_type: type !== 'timed' && type !== 'numbered'
        });
        this.setState({
            err_files: null
        });

        if(name.trim().length > 2 && (type === 'timed' || type === 'numbered')){
            this.props.groupEdit(item._id, name, desc, files[0], type);
        }
        //console.log(this.state);
    }

    renderActionsButton(){
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._save} size="large" color="primary">
                        Save
                    </Button>
                    <Button onClick={hideDialog} size="large" color="secondary">
                        Dismiss
                    </Button>
                </div>
        );
        }
    }
    render() {
        const {classes} = this.props;
        const {name,type, desc, err_name, err_type, err_files} = this.state;
        return (
            <DialogContent>
                <Typography gutterBottom variant="h5" component="h3">
                    New Group
                </Typography>
                <Divider/>
                <form className={classes.container} autoComplete="on">
                    <div className={classes.inline}>
                        <TextField
                            id="outlined-name"
                            label="English Name"
                            error={err_name}
                            className={classes.textField}
                            value={name}
                            onChange={this._handleChange('name')}
                            margin="normal"
                            variant="outlined"
                            required
                        />
                        <FormControl variant="outlined"
                                     className={classes.formControl}
                                     error={err_type}
                                     required>
                            <InputLabel
                                ref={ref => {
                                    this.InputLabelRef = ref;
                                }}
                                htmlFor="outlined-type-simple"
                            >
                                Group Type
                            </InputLabel>
                            <Select
                                value={type}
                                className={classes.select}
                                onChange={this._handleChange('type')}
                                input={
                                    <OutlinedInput
                                        labelWidth={this.state.labelWidth}
                                        name="type"
                                        id="outlined-type-simple"
                                    />
                                }>
                                <MenuItem value={'timed'} key={'timed'}>Timed (Include Time Table)</MenuItem>
                                <MenuItem value={'numbered'} key={'numbered'}>Numbered</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <TextField
                        id="outlined-detail"
                        label="Description"
                        className={classes.textArea}
                        value={desc}
                        multiline={false}
                        rows={1}
                        onChange={this._handleChange('desc')}
                        margin="normal"
                        variant="outlined"
                    />
                </form>
                <DropZone
                    error={err_files}
                    onupdatefiles={this._onUpdateFiles}/>
                <CardActions>
                    {this.renderActionsButton()}
                </CardActions>
            </DialogContent>
        );
    }
}

const mapStateToProps = state => {
    const {error} = state.groups;
    const {loading} = state.general;
    return {loading, error};
};

export default connect(mapStateToProps, {groupAdd, groupEdit})(withStyles(styles)(GroupForm));
