import axios from 'axios';
import Config from '../Config';

class ProductApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'product',
            timeout: Config.TIME_OUT,
        });
    }

    get(user, page, limit, sort=null, dir=null) {
        let url = `?page=${page}&limit=${limit}`;
        if(sort){
            url += `&sort=${sort}:${dir}`
        }
        return this.instance({
            method: 'get',
            url,
            headers: {'x-auth-token': user}
        });
    }
    //name, price, duration, qty, group, detail, summery, highlights, image
    add(user, name, prices, duration, /*qty,*/ group, detail, summery, highlights, image) {
        let data = new FormData();
        data.append('name', name);
        data.append('detail', detail);
        data.append('summery', JSON.stringify(summery));
        if(highlights !== null)
            data.append('highlights', JSON.stringify(highlights));
        data.append('prices', JSON.stringify(prices));
        data.append('duration', duration);
        // data.append('qty', qty);
        data.append('group', group);
        data.append('image', image);

        return this.instance({
            method: 'post',
            url:'/',
            headers: {'x-auth-token': user},
            data
        });
    }
    delete(user, id) {
        return this.instance({
            method: 'delete',
            url: `/${id}`,
            headers: {'x-auth-token': user}
        });
    }
    update(user, _id, name, prices, duration, /*qty,*/ group, detail, summery, highlights, image) {
        let data = new FormData();
        if(name)
            data.append('name', name);
        if(summery)
            data.append('summery', JSON.stringify(summery));
        if(detail !== null)
            data.append('detail', detail);
        if(duration)
            data.append('duration', duration);
        console.log('highlights',highlights);
        if(highlights !== null)
            data.append('highlights', JSON.stringify(highlights));
        if(prices !== null)
            data.append('prices', JSON.stringify(prices));
        // if(qty)
        //     data.append('qty', qty);
        if(group)
            data.append('group', group);
        if(image)
            data.append('image', image);

        return this.instance({
            method: 'put',
            url:`/${_id}`,
            headers: {'x-auth-token': user},
            data
        });
    }
    updateStatus(user, _id, status) {
        let data = new FormData();
        if(status)
            data.append('status', status);

        return this.instance({
            method: 'put',
            url:`/${_id}`,
            headers: {'x-auth-token': user},
            data
        });
    }
    search(user, query, page, limit, sort=null, dir=null) {
        let url = `?page=${page}&limit=${limit}&query=${query}`;
        if(sort){
            url += `&sort=${sort}:${dir}`
        }
        return this.instance({
            method: 'get',
            url,
            headers: {'x-auth-token': user}
        });
    }

}
export default new ProductApi();