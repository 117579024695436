import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import Badge from "@material-ui/core/Badge";
import { faBoxOpen, faBoxes, faTags, faList} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "react-router-dom";
import {Tooltip} from "@material-ui/core/es/index";

function renderOrderIcon(newOrders){
    if(newOrders.length > 0){
        return(
            <Badge badgeContent={newOrders.length} color="secondary">
                <ShoppingCartIcon />
            </Badge>
        );
    }
    else {
        return(<ShoppingCartIcon />);
    }
}
export const MainDrawerItems = (newOrders, accessLevel)=>{
    console.log(JSON.stringify(accessLevel));
    return(
        <div>
            {/*<ListItem button component={Link} to="/">*/}
            {/*    <Tooltip title={"Dashboard"}>*/}
            {/*        <ListItemIcon>*/}
            {/*            <DashboardIcon />*/}
            {/*        </ListItemIcon>*/}
            {/*    </Tooltip>*/}
            {/*    <ListItemText primary="Dashboard" />*/}
            {/*</ListItem>*/}
            {
                accessLevel.orders.read
                    ?<ListItem button component={Link} to="/Orders">
                        <Tooltip title={"Orders"}>
                            <ListItemIcon>
                                {renderOrderIcon(newOrders)}
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Orders" />
                    </ListItem>
                    :null
            }
            {
                accessLevel.customers.read
                    ? <ListItem button component={Link} to="/Customers">
                        <Tooltip title={"Customers"}>
                            <ListItemIcon>
                                <PeopleIcon/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Customers"/>
                    </ListItem>
                    : null
            }
            {
                accessLevel.products.read
                    ? <ListItem button component={Link} to="/Products">
                        <Tooltip title={"Activities"}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faBoxOpen} style={{marginRight: 5}}/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText style={{textDecoration: 'none'}} primary="Activities"/>
                    </ListItem>
                    : null
            }
            {
                accessLevel.groups.read
                    ?<ListItem button component={Link} to="/Groups">
                        <Tooltip title={"Cities"}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faBoxes} style={{marginRight:5}}/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText style={{textDecoration:'none'}} primary="Cities" />
                    </ListItem>
                    :null
            }
            {
                accessLevel.coupons.read
                    ?<ListItem button component={Link} to="/coupons">
                        <Tooltip title={"Coupons"}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faTags} style={{marginRight:5}}/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText style={{textDecoration:'none'}} primary="Coupons" />
                    </ListItem>
                    :null
            }
            {
                accessLevel.services.read
                    ?<ListItem button component={Link} to="/Services">
                        <Tooltip title={"Services"}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faList} style={{marginRight:5}}/>
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText style={{textDecoration:'none'}} primary="Services" />
                    </ListItem>
                    :null
            }
        </div>
    )
};