import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
let showAlertDialog;
class AlertDialog extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
        this._onAgree = this._onAgree.bind(this);
        this._onDisagree = this._onDisagree.bind(this);
    }
    componentDidMount() {
        showAlertDialog = this.showDialog;
    }

    showDialog = (title, message, onAgree=()=>{}, onDisagree=()=>{}) => {
        this.setState({
            open: true,
            title,
            message,
            onAgree,
            onDisagree
        })

    };

    closeDialog = () => {
        this.setState({ open: false });
    };

    _onDisagree(){
        this.state.onDisagree();
        this.closeDialog();
    }
    _onAgree(){
        this.state.onAgree();
        this.closeDialog();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this._onDisagree} color="primary">
                            Disagree
                        </Button>
                        <Button onClick={this._onAgree} color="primary" autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    // render() {
    //     return (
    //         <div>
    //             <Dialog
    //                 open={this.state.open}
    //                 onClose={this.handleClose}
    //                 aria-labelledby="alert-dialog-title"
    //                 aria-describedby="alert-dialog-description"
    //             >
    //                 <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
    //                 <DialogContent>
    //                     <DialogContentText id="alert-dialog-description">
    //                         {this.state.message}
    //                     </DialogContentText>
    //                 </DialogContent>
    //                 <DialogActions>
    //                     <Button onClick={this._onDisagree} color="primary">
    //                         Disagree
    //                     </Button>
    //                     <Button onClick={this._onAgree} color="primary" autoFocus>
    //                         Agree
    //                     </Button>
    //                 </DialogActions>
    //             </Dialog>
    //         </div>
    //     );
    // }
}

export {showAlertDialog};
export default AlertDialog;