import {getUser} from "./Auth";
import * as types from "./types";
import GroupApi from "../api/Group";
import {BrokenConnection, SessionExpired} from "../helpers";
import {openSnackbar} from "../components/commons/Notifier";

export const groupsFetch = (callback)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    GroupApi.get(user.token).then(
                        result => {
                            dispatch({type: types.GROUP_FETCH_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            if(callback) callback(result.data);
                        }
                    )
                    .catch((err)=>{
                        if(callback) callback(null, err);
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const groupAdd  = (name, desc, file, type)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    GroupApi.add(user.token, name, desc, file, type).then(
                        result => {
                            dispatch({type: types.GROUP_ADD_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`New group added successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });

                    // ).catch(err =>{
                    //      dispatch({type: types.GROUP_ADD_FAILED, payload: err.response.data});
                    // })
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const groupEdit = (_id, name, desc, file, type)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    GroupApi.update(user.token, _id, name, desc, file, type).then(
                        result => {
                            dispatch({type: types.GROUP_UPDATE_SUCCESS, payload: result.data});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Group has been edited successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const groupDelete = (id)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    GroupApi.delete(user.token, id).then(
                        () => {
                            dispatch({type: types.GROUP_DELETE_SUCCESS, payload: id});
                            dispatch({type: types.STOP_LOADING});
                            openSnackbar(`Group deleted successfully`);
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                    //     .catch(
                    //     err =>{
                    //         dispatch({type: types.GROUP_DELETE_FAILED, payload: err.response.data});
                    //     }
                    // )
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};
export const getProducts = (id)=>{
    return((dispatch) => {
            dispatch({type: types.START_LOADING});
            dispatch(getUser())
                .then((user)=>{
                    GroupApi.getProducts(user.token, id).then(
                        result => {
                            dispatch({type: types.GROUP_PRODUCTS_FETCH_SUCCESS, payload: result.data, groupId:id});
                            dispatch({type: types.STOP_LOADING});
                        }
                    )
                    .catch((err)=>{
                        BrokenConnection(err, dispatch);
                    });
                })
                .catch(()=>{
                    SessionExpired(dispatch);
                });
        }
    );
};