import * as types from '../actions/types';
import {hideDialog} from "../components/commons/Dialog";
import {openSnackbar} from "../components/commons/Notifier";
const INITIAL_STATE = {
    coupons:[]
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.COUPONS_FETCH_SUCCESS:
            return {...state, coupons: action.payload};
        case types.COUPON_DELETE_SUCCESS:
            let coupons = [];
            state.coupons.items.forEach(item=>{
                if(item._id !== action.payload){
                    coupons.push(item);
                }
            });
            return {...state, coupons: {items: coupons, count: coupons.length}};
        case types.COUPON_UPDATE_SUCCESS: {
            hideDialog();
            let coupons = [...state.coupons.items];
            //console.log(state.products);
            for(let i=0 ; i < coupons.length ; i++){
                if (coupons[i]._id === action.payload._id) {
                    coupons[i] = action.payload;
                }
            }
            return {...state, coupons: {items: coupons, count: coupons.length}};

        }
        case types.COUPON_ADD_SUCCESS:
            hideDialog();
            const {items, count} = state.coupons;
            return {...state, coupons:{ items: [...items, action.payload], count: count+1 }, loading: false, error: ""};
        // case types.PRODUCTS_ADD_FAILED:
        //     openSnackbar(action.payload);
        //     return {...state, loading: false, error: action.payload};
        default:
            return state;
    }
}