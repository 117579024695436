import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {IconButton} from "@material-ui/core";
import {hideDialog} from '../commons/Dialog'
import {Button} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import {updateOrderStatus} from '../../actions';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import canceled from '../../images/canceled.png';
import delivered from '../../images/delivered.jpg';
import processing from '../../images/processing.png';
import {Tooltip} from "@material-ui/core/es/index";

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin:theme.spacing(5),
        alignItems:'center',
        alignContents:'center'
    },
    inline:{
        display: 'flex',
        // flexWrap: 'wrap',
        // flexGrow: 1,
        flexDirection: 'row',
        alignItems:'center',
        alignContents:'center'
    },
    spinner:{
        // marginLeft: theme.spacing.unit * 3,
        // marginLeft: theme.spacing.unit * 3,
        marginBottom: theme.spacing(4)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: 550,
        overflowY:'auto',
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(1),
        flexGrow: 1
    },
    select:{
        // width:150
        //flexGrow: 1,
        //marginLeft: theme.spacing.unit,
        //marginRight: theme.spacing.unit
    }
});

class GroupForm extends Component {

    constructor(props){
        super(props);
        this.initialState = {
                canceledStyle:{backgroundColor:'transparent'},
                processingStyle:{backgroundColor:'transparent'},
                deliveredStyle:{backgroundColor:'transparent'},
            };
        this.state = this.initialState;
        this._save = this._save.bind(this);
    }
    _onClick(status){
        switch (status) {
            case 'canceled':
                this.setState({
                    ...this.initialState,
                    canceledStyle: {backgroundColor: '#f44336'},
                    status
                });
                break;
            case 'processing':
                this.setState({
                    ...this.initialState,
                    processingStyle: {backgroundColor: '#64DD17'},
                    status
                });
                break;
            case 'delivered':
                this.setState({
                    ...this.initialState,
                    deliveredStyle: {backgroundColor: '#64DD17'},
                    status
                });
                break;
        }
    }
    _save(){
        this.props.updateOrderStatus(this.props.items, this.state.status);
    }
    renderActionsButton(){
        const {classes, loading} = this.props;
        if(loading){
            return(
                <div style={{flexGrow: 1}}>
                    <LinearProgress className={classes.spinner}  color="secondary" variant="query"/>
                </div>
            );
        }else {
            return(
                <div>
                    <Button onClick={this._save} size="large" color="primary">
                        Save
                    </Button>
                    <Button onClick={hideDialog} size="large" color="secondary">
                        Dismiss
                    </Button>
                </div>
        );
        }
    }
    render() {
        const {classes} = this.props;
        // const {} = this.state;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <Tooltip title={"Cancel orders"}>
                        <IconButton style={this.state.canceledStyle} onClick={()=>{this._onClick('canceled')}}>
                            <img src={canceled} height={100} width={100}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Processing orders"}>
                        <IconButton style={this.state.processingStyle} onClick={()=>{this._onClick('processing')}}>
                            <img src={processing} height={100} width={100}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delivered orders"}>
                        <IconButton style={this.state.deliveredStyle} onClick={()=>{this._onClick('delivered')}}>
                            <img src={delivered} height={100} width={100}/>
                        </IconButton>
                    </Tooltip>
                </CardContent>
                <CardActions>
                    {this.renderActionsButton()}
                </CardActions>
            </Card>
        );
    }
}

const mapStateToProps = state => {

    const {error} = state.groups;
    const {loading} = state.general;
    return {loading, error};
};

export default connect(mapStateToProps, {updateOrderStatus})(withStyles(styles)(GroupForm));
