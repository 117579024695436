import axios from 'axios';
import Config from '../Config';

class GalleryApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'gallery',
            timeout: Config.TIME_OUT,
        });
    }
    get(user) {
        return this.instance({
            method: 'get',
            url: '/',
            headers: {'x-auth-token': user}
        });
    }
    add(user, image, tag) {
        let data = new FormData();
        data.append('tag', tag);
        data.append('image', image);

        return this.instance({
            method: 'post',
            url:'/',
            headers: {'x-auth-token': user},
            data
        });
    }
    delete(user, _ids) {
        return this.instance({
            method: 'DELETE',
            url: `/${_ids}`,
            headers: {'x-auth-token': user}
        });
    }


}
export default new GalleryApi();