import * as types from '../actions/types';
import {hideDialog} from '../components/commons/Dialog';
import {openSnackbar} from '../components/commons/Notifier';
const INITIAL_STATE = {
    groups:[]
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.GROUP_FETCH_SUCCESS:
            return {...state, groups: action.payload};
        case types.GROUP_PRODUCTS_FETCH_SUCCESS:
            //console.log("GROUP_PRODUCTS_FETCH_SUCCESS");
            let _groups = [...state.groups];
            _groups.map(group=>{
                if(group._id == action.groupId){
                    group.products = action.payload.items;
                }
                return group;
            });
            return {...state, groups: _groups};

        case types.GROUP_DELETE_SUCCESS:
            let groups = [];
            ////console.log(state.products);
            state.groups.forEach(item=>{
                if(item._id !== action.payload){
                    groups.push(item);
                }
            });
            return {...state, groups};
        case types.GROUP_DELETE_FAILED:
            openSnackbar(action.payload);
            return state;
        case types.GROUP_ADD_SUCCESS:
            hideDialog();
            return {...state, groups: [...state.groups, action.payload], loading: false, error: ""};
        case types.GROUP_UPDATE_SUCCESS: {
            //console.log('GROUP_EDIT_SUCCESS:');
            hideDialog();
            let groups = [...state.groups];
            ////console.log(state.products);
            for(let i=0 ; i < groups.length ; i++){
                if (groups[i]._id === action.payload._id) {
                    groups[i] = action.payload;
                }
            }
            return {...state, groups};
        }
        case types.GROUP_ADD_FAILED:
            openSnackbar(action.payload);
            return {...state, loading: false, error: action.payload};
        case types.GROUP_PRODUCTS_UPDATE_SUCCESS: {
            //console.log('GROUP_PRODUCTS_UPDATE_SUCCESS:');
            hideDialog();
            let groups = [...state.groups];
            ////console.log(state.products);
            for (let i = 0; i < groups.length; i++) {
                if (groups[i]._id === action.payload.group._id) {
                    if(groups[i].products) {
                        for (let j = 0; j < groups[i].products.length; j++){
                            if(groups[i].products[j]._id === action.payload._id){
                                groups[i].products[j] = action.payload;
                                break;
                            }
                        }
                        break;
                    }
                }
            }
            return {...state, groups};
        }


        default:
            return state;
    }
}