import * as types from '../actions/types';
const INITIAL_STATE = {
    customers:[]
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.CUSTOMERS_FETCH_SUCCESS:
            return {...state, customers: action.payload};
        default:
            return state;
    }
}