import * as types from '../actions/types';
import {hideDialog} from "../components/commons/Dialog";
const INITIAL_STATE = {
    user: null
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.GET_USER:
            return {...state, user: action.payload};

        case types.LOGOUT_USER:
            hideDialog();
            return {...state, user: null};

        case types.LOGIN_USER_SUCCESS:
            return { ...state, ...INITIAL_STATE, user: action.payload };

        case types.UPDATE_USER:
            return { ...state, user: { ...state.user, [action.key]:action.payload }};

        // case types.LOGIN_USER_FAILED:
        //     return state;

        default:
            return state;
    }
}