import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import {Typography} from "@material-ui/core/es/index";

const useStyles = makeStyles(theme => ({
    root: {
        width: '150%',
        marginTop: theme.spacing(3),
        margin:25,
        overflowX: 'auto',
        // alignItems:'center'
    },
    table: {
        minWidth: 650,
    },
    title: {
        flex: '0 0 auto',
        marginLeft:16,
        fontWeight:'bold',
        marginTop:16
    },
}));

export default function OrderDetails({item}) {
    if(!item)
        return null;

    const classes = useStyles();
    console.log(item);
    const {summery} = item;
    return (
        <Paper className={classes.root}>
            <div className={classes.title}>
                <Typography color="inherit" variant="subtitle1">
                    More Info
                </Typography>
            </div>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell >Itinerary</TableCell>
                        <TableCell >Highlights</TableCell>
                        <TableCell style={{width:300}}>Summery</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">{item.detail}</TableCell>
                        <TableCell component="th" scope="row">{item.highlights}</TableCell>
                        <TableCell component="th" scope="row" >
                            <div>
                            {
                                summery.freeCancellation
                                    ? <p style={{display:'list-item'}}>Free cancellation available</p>
                                    : null
                            }
                            {
                                summery.eVoucher
                                    ? <p style={{display:'list-item'}}>E-voucher(printout not required)</p>
                                    : null
                            }
                            {
                                summery.english
                                    ? <p style={{display:'list-item'}}>Offered in: English</p>
                                    : null
                            }
                            {
                                summery.muliLang
                                    ? <p style={{display:'list-item'}}>Offered in: multipleLanguage</p>
                                    : null
                            }
                            {
                                summery.noBookingFee
                                    ? <p style={{display:'list-item'}}>No Booking Fees</p>
                                    : null
                            }
                            {
                                summery.picksUp
                                    ? <p style={{display:'list-item'}}>Picks up from select hotels</p>
                                    : null
                            }
                            </div>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Paper>
    );
}