import axios from 'axios';
import Config from '../Config';

class TimeTableApi {

    constructor() {
        this.instance = axios.create({
            baseURL: Config.API_URL + 'timeTable',
            timeout: Config.TIME_OUT,
        });
    }
    add(user, startTime, endTime, products) {
        let data = new FormData();
        data.append('products', products);
        data.append('startTime', startTime);
        data.append('endTime', endTime);

        return this.instance({
            method: 'post',
            url:'/',
            headers: {'x-auth-token': user},
            data:{
                products,
                startTime,
                endTime
            }
        });
    }
    get(user, page, limit,  sort=null, dir=null) {
        // let url=`?limit=${limit}&page=${page}`;
        // if(sort && dir)
        //     url += `&sort=${sort}:${dir}`;

        return this.instance({
            method: 'get',
            url:'/',
            headers: {'x-auth-token': user}
        });
    }
    delete(user, id) {
        return this.instance({
            method: 'delete',
            url: `/${id}`,
            headers: {'x-auth-token': user}
        });
    }

}
export default new TimeTableApi();