import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarSelectStyles = {
    iconButton: {
        marginRight: "24px",
        top: "50%",
        display: "inline-block",
        position: "relative",
        // transform: "translateY(-50%)"
    },
    deleteIcon: {
        color: "#000"
    }
};

class CustomToolbarSelect extends React.Component {
    render() {
        const { classes, onEdit, onDelete, selectedRows } = this.props;
        if(selectedRows.data.length === 1)
            return (
                <div className={"custom-toolbar-select"}>
                    <Tooltip title={"Edit Items"}>
                        <IconButton className={classes.iconButton} onClick={()=>{onEdit(selectedRows);}}>
                            <EditIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete Items"}>
                        <IconButton className={classes.iconButton} onClick={()=>{onDelete(selectedRows);}}>
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                </div>
            );
        else
            return (
                <div className={"custom-toolbar-select"}>
                    <Tooltip title={"Delete Items"}>
                        <IconButton className={classes.iconButton} onClick={()=>{onDelete(selectedRows);}}>
                            <DeleteIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                </div>
            );

    }
}

export default withStyles(defaultToolbarSelectStyles, {
    name: "CustomToolbarSelect"
})(CustomToolbarSelect);
