import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import LinearProgress from "@material-ui/core/LinearProgress";
import withStyles from '@material-ui/core/styles/withStyles';
import {loginUser} from '../../actions';
import {connect} from 'react-redux';
import Config from '../../Config';


const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(6))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        maxWidth:450,
        minWidth:330,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
        backgroundColor:'rgba(255,255,255,0.1)',
        position:'absolute',
        top: '50%',
        left:'50%',
        transform:'translate(-50%,-50%)'
    },
    avatar: {
        margin: theme.spacing(3),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding:theme.spacing(2),
        paddingTop:0
    },
    submit: {
        marginTop: theme.spacing(3),
        backgroundColor:'#4692e7',
        borderRadius:100,
    },
    backgroundImage:{
        backgroundImage: `url(${require('../../images/background.jpg')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width:'100%',
        height:'100%'
    }
});
class Login extends Component{

    constructor(props){
        super(props);
        this.state = {
            email:'',
            password:''
        };
        this._onClick = this._onClick.bind(this);
        this._onEmailChanged = this._onEmailChanged.bind(this);
        this._onPasswordChanged = this._onPasswordChanged.bind(this);
    }

    _onClick(event){
        event.preventDefault();
        this.props.loginUser(this.state);
        //console.log(this.state);
    }

    _onEmailChanged(event){
        this.setState({
            ...this.state, email:event.target.value
        });
    }
    _onPasswordChanged(event){
        this.setState({
            ...this.state, password:event.target.value
        });
    }

    render() {
        const { classes, loading } = this.props;
        console.log('loading:' ,loading);
        return (
            <div className={classes.backgroundImage}>
                {
                    loading
                    ?<LinearProgress color="primary" variant="query"/>
                    :null
                }
                <main className={classes.main}>
                    <CssBaseline/>
                    <Paper className={classes.paper}>
                        <img alt='logo' src={require('../../images/logo.png')} width="300" style={{"-webkit-user-drag": 'none', marginTop:16}}/>
                        <form className={classes.form}>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="email">Email Address</InputLabel>
                                <Input id="email" name="email" autoComplete="email" onChange={this._onEmailChanged}/>
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Input name="password" type="password" id="password" autoComplete="current-password"
                                       onChange={this._onPasswordChanged}/>
                            </FormControl>
                            <Button
                                onClick={this._onClick}
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Sign in
                            </Button>
                        </form>
                        <p style={{textAlign:'center', marginBottom:0, color:'#666666'}}>{Config.VERSION}</p>
                    </Paper>
                </main>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {

    const {loading} = state.general;
    const {error, user} = state.auth;
    return{loading, error, user};
};
export default connect(mapStateToProps,{loginUser})(withStyles(styles)(Login));