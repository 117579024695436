import * as types from '../actions/types';
const INITIAL_STATE = {
    orders:{
        items:[],
        count:0
    },
    newOrders:[],
    order:{}
};

export default (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case types.ORDERS_FETCH_SUCCESS:
            let items = [];
            action.payload.items.forEach((item)=>{
                if(item.status === 'paid' || item.status === 'overdue'){
                    items.push(item);
                }
            });
            //console.log(items);
            return {...state, orders: action.payload, newOrders:items};
        case types.ORDER_FETCH_SUCCESS:
            return {...state, order: action.payload};
        case types.ORDER_UPDATE_SUCCESS:{
            //console.log('ORDER_UPDATE_SUCCESS');
            //console.log(action.payload);
            let orders = [...state.orders.items];
            for(let i = 0 ; i < action.payload.length ; i++){
                for(let j = 0; j < orders.length ; j++){
                    if(action.payload[i] === orders[j]._id){
                        orders[j].status = action.status;
                        break;
                    }
                }
            }
            return {...state, orders:{items:orders, count:state.orders.count}};
        }

        default:
            return state;
    }
}