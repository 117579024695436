import React, {Component} from "react";
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import IconButton from "@material-ui/core/IconButton";
import ViewWeek from '@material-ui/icons/CalendarToday';
import MUIDataTable from "mui-datatables";
import {ordersFetch, ordersSearch} from '../../actions';
import {showAlertDialog} from '../commons/AlertDialog';
import {showDialog} from '../commons/Dialog';
import ToolbarSelect from './ToolbarSelect';
import OrderStatusChanger from './OrderStatusChanger';
import Moment from 'moment';
import {LinearProgress, Tooltip, Button} from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import {getPreviousMonday} from '../../Utils/date';
import {MuiTableWrapper} from "../commons/MuiTableWrapper";
import OrderStatusChangerForm from "./orderStatusChangerForm";
const styles = theme => ({
    button: {
        color: '#56b75c'
    }
});

class OrdersList extends Component {

    constructor(props){
        super(props);
        this.state={
            view: null,//'week',
            from: new Date().addDays(1).toISOString(),
            to: getPreviousMonday().addDays(1).toISOString()
        };
        this.loadOrders();
    }

    loadOrders=(from, to)=>{
        this.props.ordersFetch(1,10,'dateTime','asc', from, to, 'full');
    }
    prepareData=(items)=>{
        if(!items)
            return [];
        return items.map((item)=>{
            let user  = 'User not found!';
            let phone = '';
            if(item.user){
                user = item.user.name;
                phone = item.user.phone;
            }
            let sum = 0;
            item.orderItems.forEach(item=>{
                if(item.prices && item.passengersObj) {
                    const passengersObj = item.passengersObj;
                    console.log(passengersObj);
                    console.log(item.prices);
                    sum += passengersObj.adult  && item.prices.adult  ? item.prices.adult  * passengersObj.adult  : 0;
                    sum += passengersObj.senior && item.prices.senior ? item.prices.senior * passengersObj.senior : 0;
                    sum += passengersObj.child  && item.prices.child  ? item.prices.child  * passengersObj.child  : 0;
                    sum += passengersObj.infant && item.prices.infant ? item.prices.infant * passengersObj.infant : 0;
                }
            });
            const moment_del = Moment(item.dateTime);
            const moment_reg = Moment(item.registerDateTime);
            let dateTime = moment_del.calendar();
            let registerDateTime = moment_reg.calendar();
            // let receiveMethod = (item.receiveMethod === 'delivery') ? "Delivery" : "Take Away";
            const overdue = sum - item.orderInfo.coupon.value;
            // let paymentStatus = (sum < item.orderInfo.coupon.value)
            //     ? <strong style={{color:'#3761dd'}}>Paid</strong>
            //     : <strong style={{color:'#ff2011'}}>Due ${overdue > 0 ? overdue : ""}</strong>;
            let status = "";
            switch (item.status) {
                case 'paid':
                    status = <strong style={{color:'#3761dd'}}>Paid</strong>;
                    break;
                case 'overdue':
                    status = <strong style={{color:'rgb(255, 32, 17)'}}>{overdue > 0 ? "$"+overdue : ""} Overdue</strong>;
                    break;
                case 'delivered':
                    status = <strong style={{color:'#33a136'}}>Delivered</strong>;
                    break;
                case 'canceled':
                    status = <strong style={{color:'#878787'}}>Canceled</strong>;
                    break;
            }
            return {
                ...item,
                user,
                phone,
                dateTime,
                status,
                registerDateTime,
                totalPrice: '$'+sum,
                // paymentStatus
            };
        });
    };

    _onTableChange=(action, tableState)=>{
        if(action === 'sort' || action !== 'propsUpdate' || action === 'search') {
            if (action === 'rowsSelect' || action === 'expandRow') return;
            const {page, rowsPerPage, searchText, activeColumn, columns} = tableState;
            let sort = null;
            let sortDir = null;

            if (activeColumn !== null) {
                sort = columns[activeColumn].name;
                sortDir = columns[activeColumn].sortDirection;
                switch (sort) {
                    case "name":
                        sort = "name";
                        break;
                    case "detail":
                        sort = "detail";
                        break
                }
            }
            const {view, from, to} = this.state;
            if(view == null) {
                if (searchText)
                    this.props.ordersSearch(searchText, page + 1, rowsPerPage, sort, sortDir,null,null, 'full');
                else
                    this.props.ordersFetch(page + 1, rowsPerPage, sort, sortDir, null,null,'full');
            }
            else {
                if (searchText)
                    this.props.ordersSearch(searchText, page + 1, rowsPerPage, sort, sortDir, from, to, 'full');
                else
                    this.props.ordersFetch(page + 1, rowsPerPage, sort, sortDir, from, to, 'full');
            }
        }
    }
    _onChangeOrderStatus=(selectedRows)=>{
        const {items} = this.props.orders;
        let selectedOrders = [];
        selectedRows.data.forEach((data)=>{
            selectedOrders.push(items[data.dataIndex]._id);
        });
        if(selectedOrders.length > 0) {
            showDialog(
                <OrderStatusChanger items={selectedOrders}/>
            )
        }
    }
    _onRowsDelete=(rowsDeleted)=>{
        let names = "";
        const {productDelete, products} = this.props;
        this.setState({data: this.prepareData(products.items)});
        rowsDeleted.data.forEach((data)=>{
            names += ' "' + products.items[data.index].name + '"  ';
        });

        showAlertDialog('Attention', `you are about to delete ${names}.`, ()=>{
            rowsDeleted.data.forEach((data)=>{
                productDelete(products.items[data.index]._id);
            });
        });
        return false;
    }
    _customToolbar=()=>{
        const {view} = this.state;
        const color = (view === 'week' ?'#56b75c':'');
        return (
            <Tooltip title={"Orders of this week"}>
                <IconButton onClick={this._WeekView} style={{color}} aria-label="Week">
                    <ViewWeek />
                </IconButton>
            </Tooltip>
        );
    }
    _WeekView=()=>{
        const {view, from, to} = this.state;
        if(view == null) {
            this.loadOrders(from, to);
            this.setState({
                view: 'week'
            });
        }
        else {
            this.loadOrders();
            this.setState({
                view: null
            });
        }
    }
    _changeOrder = (_id) => {
        const {items, count} = this.props.orders;
        console.log(_id);
        showDialog(
            <OrderStatusChangerForm orderId={_id}/>
        )
    }

    renderExpandableRow=(rowData, rowMeta)=>{
        const {items} = this.props.orders;
        const item = items[rowMeta.dataIndex];
        if(item)
            return <OrderDetails items={item.orderItems} />;
        else
            return null;
    }
    renderLoading=(loading)=>{
        if(loading){
            return(
                <LinearProgress style={{color:'#4692e7'}}/>
            )
        }else{
            return(<view></view>);
        }
    }
    render() {
        const {user} = this.props;
        const {items, count} = this.props.orders;
        const data = this.prepareData(items);
        let columns = [
            {
                name:"user",
                label:"Customer"
            },
            {
                name:"phone",
                label:"Phone"
            },
            {
                name:"status",
                label:"Order Status",
            },
            // {
            //     name:"paymentStatus",
            //     label:"Payment Status",
            // },
            {
                name:"totalPrice",
                label:"Total Price",
            },
            {
                name:"registerDateTime",
                label:"Register DateTime"
            },
        ];
        if(user.accessLevel.orders.write){
            columns.push({
                name:"_id",
                label:" ",
                options: {
                    customBodyRender: (_id, tableMeta, updateValue) => {
                        console.log(_id, tableMeta, updateValue);
                        // const data = tableMeta.tableData[tableMeta.rowIndex];
                        return (
                            <Button color={"primary"}
                                    variant={"contained"}
                                    onClick={()=>{this._changeOrder(_id)}}
                            >
                                Take action
                            </Button>
                        )
                    }
                }

            })
        }
        const options = {
            count,
            filterType: "multiselect",
            responsive: "scroll", //scrollMaxHeight | scrollFullHeight | stacked
            viewColumns:false,
            filter:false,
            search:true,
            serverSide: true,
            fixedHeader:false,
            expandableRows:true,
            onTableChange: this._onTableChange,
            isRowSelectable: ()=>(user.accessLevel.orders.write),
            customToolbar: this._customToolbar,
            renderExpandableRow: this.renderExpandableRow,
            customToolbarSelect: selectedRows => (
                <ToolbarSelect selectedRows={selectedRows} onClick={this._onChangeOrderStatus}/>
            )
        };
        if(user.accessLevel.orders.read) {
            return (
                <MuiTableWrapper>
                    {this.renderLoading(this.props.loading)}
                    <MUIDataTable
                        // key={count}
                        title={"Orders"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </MuiTableWrapper>
            );
        }
        else {
            return <strong style={{fontSize:32}}>403 Access Denied</strong>
        }
    }
}

const mapStateToProps = state => {
    const {user} = state.auth;
    const {loading} = state.general;
    const {orders} = state.orders;
    return {orders, loading, user};
};

export default connect(mapStateToProps,{ordersFetch, ordersSearch})(withStyles(styles)(OrdersList));
